import AnnouncementIcon from '@mui/icons-material/Announcement';
import AnnouncementList from './AnnouncementList';
import AnnouncementEdit from './AnnouncementEdit';
import AnnouncementCreate from './AnnouncementCreate';

export default {
    list: AnnouncementList,
    create: AnnouncementCreate,
    edit: AnnouncementEdit,
    icon: AnnouncementIcon,
};