import * as React from 'react';
import {
    CreateButton,
    DateField,
    FilterButton,
    List,
    NumberField,
    SearchInput,
    TopToolbar,
    TextField,
    DatagridConfigurable,
    SelectColumnsButton,
    useTranslate,
    FunctionField,
    Title,
    useNotify,
    WithListContext,
    DatagridLoading,
} from 'react-admin';
import { useMediaQuery, Theme, Typography } from '@mui/material';
import { Alert } from '@mui/material';
import { Link } from 'react-router-dom';
import CustomStatusField from '../../controls/CustomStatusField';
import TeacherListAside from './TeacherListAside';
import get from 'lodash/get';
// import ProductFullNameField from '../product/ProductFullNameField';
// import SubscriberFullNameField from './SubscriberFullNameField';
import CustomLogAsTeacherButton from '../../controls/CustomLogAsTeacherButton';
import CustomPagination from '../../common/CustomPagination';

const teacherFilters = [<SearchInput source="q" alwaysOn />];

const ListActions = () => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <TopToolbar>
            {isSmall ? <FilterButton /> : null}
            <SelectColumnsButton />
            {/* <CreateButton /> */}
            {/* <ExportButton /> */}
            {/* <ListViewButton /> */}
        </TopToolbar>
    );
};

const TeacherList = () => {
    const notify = useNotify();
    const handleOnClick = e => {
        e.stopPropagation();
        navigator.clipboard.writeText(e.target.innerText);
        //notify(e.target.innerText + ' kopioitu leikepöydälle.', { type: 'info' });
        notify(
            <Alert severity="info">
                {e.target.innerText} kopioitu leikepöydälle.
            </Alert>
        );
    };

    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const sortable = [
        'public.firstname',
        'public.lastname',
        'username',
        'last_login',
        'registered',
    ];

    const translate = useTranslate();

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));

    return (
        <List
            filters={isSmall ? teacherFilters : undefined}
            sort={{ field: 'registered', order: 'DESC' }}
            perPage={25}
            aside={<TeacherListAside />}
            actions={<ListActions />}
            filter={{ need_count: false }}
            title={<Title title="My Custom Page" />}
            pagination={<CustomPagination />}
        >
            <WithListContext
                render={({ isLoading, isFetching, data }) =>
                    isLoading || isFetching ? (
                        <DatagridLoading nbChildren={8} nbFakeLines={8} />
                    ) : (
                        <DatagridConfigurable
                            //optimized
                            omit={['guid']}
                            rowClick="edit"
                            //rowClick={(id: any, basePath: any, record: any) => {window.open("https://oppimateriaalit-aspa.otava.fi/#/"+basePath+"/"+id, "_blank");return false;}}
                            sx={{
                                '& .column-groups': {
                                    md: { display: 'none' },
                                    lg: { display: 'table-cell' },
                                },
                            }}
                        >
                            {/* {columns} */}
                            <TextField
                                source="guid"
                                key="guid"
                                sortable={sortable.includes('guid')}
                            />
                            <TextField
                                source="public.firstname"
                                key="firstname"
                                sortable={sortable.includes('public.firstname')}
                                onClick={handleOnClick}
                            />
                            <TextField
                                source="public.lastname"
                                key="lastname"
                                sortable={sortable.includes('public.lastname')}
                                onClick={handleOnClick}
                            />
                            <TextField
                                source="username"
                                key="username"
                                sortable={sortable.includes('username')}
                                onClick={handleOnClick}
                            />
                            <FunctionField
                                source="school"
                                key="school"
                                render={record =>
                                    record.group
                                        .filter(g => g.type === 2)
                                        .map(pg => (
                                            <Link
                                                key={pg.guid}
                                                style={{
                                                    textDecoration: 'none',
                                                }}
                                                className="inner_link"
                                                to={'/school/' + pg.guid}
                                                onClick={e =>
                                                    e.stopPropagation()
                                                }
                                            >
                                                <Typography
                                                    variant="body2"
                                                    display="flex"
                                                    flexWrap="nowrap"
                                                    alignItems="center"
                                                    component="div"
                                                >
                                                    {pg.name}
                                                </Typography>
                                            </Link>
                                        ))
                                }
                                sortable={sortable.includes('school')}
                            />
                            <DateField
                                source="last_login"
                                key="last_login"
                                showTime={true}
                                locales="fi-FI"
                                sortable={sortable.includes('last_login')}
                            />

                            <FunctionField
                                source="registered"
                                key="registered"
                                sortable={sortable.includes('registered')}
                                render={record =>
                                    record.status === 0 ? (
                                        new Date(
                                            record.registered
                                        ).toLocaleDateString('fi-FI')
                                    ) : (
                                        <span
                                            title={`${new Date(
                                                record.registered
                                            ).toLocaleDateString('fi-FI')}`}
                                        >
                                            {translate(
                                                `resources.user.status.${record.status}`
                                            )}
                                        </span>
                                    )
                                }
                            />
                            <CustomLogAsTeacherButton /* sortable={sortable.includes('log_as_teacher')} */
                            />
                            <FunctionField
                                label="Lähde"
                                render={record =>
                                    get(record, 'services', []).map(service =>
                                        service != 'crm' &&
                                        service != 'fl_opettaja' ? (
                                            <span
                                                key={`service_${service}`}
                                                style={{
                                                    textDecoration: 'none',
                                                    color: '#fff',
                                                    fontSize: 12,
                                                    padding: '1px 6px',
                                                    borderRadius: 3,
                                                    marginRight: 3,
                                                    height: 15,
                                                    marginLeft: 3,
                                                    background:
                                                        get(
                                                            record,
                                                            'mpass.error'
                                                        ) && service === 'mpass'
                                                            ? 'red'
                                                            : 'rgb(96, 125, 139, .5)',
                                                }}
                                            >{`${service
                                                .replace('opepalvelu', 'OOP')
                                                .replace('finnlectura', 'FL')
                                                .toUpperCase()}`}</span>
                                        ) : (
                                            ''
                                        )
                                    )
                                }
                            />
                        </DatagridConfigurable>
                    )
                }
            />
        </List>
    );
};

export default TeacherList;
