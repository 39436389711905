import { createElement } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import {
    Stack,
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Alert,
} from '@mui/material';
import { useTranslate } from 'react-admin';
import {
    numberFormatter,
    appIcons,
    resolveDate,
    daysBetweenDates,
} from './common';
import { Filter, Handler, NewUser } from './types';

const NewUsersByRoles = (data: NewUser[]) => {
    const translate = useTranslate();

    if (!data[0]) {
        return (
            <Alert severity="warning" sx={{ padding: 10 }}>
                {translate('statistics.nodata')}
            </Alert>
        );
    }

    return (
        <List>
            {data.map(item => {
                const ItemText =
                    item.app === 'opiskelija'
                        ? translate('statistics.fields.students')
                        : item.app === 'opepalvelu'
                        ? translate('statistics.fields.teachers')
                        : translate('statistics.fields.pupils');
                const Icon =
                    item.app === 'opiskelija'
                        ? appIcons.student
                        : item.app === 'opepalvelu'
                        ? appIcons.teacher
                        : appIcons.pupil;
                return (
                    <ListItem key={item.guid}>
                        <ListItemAvatar>
                            <Avatar
                                component={Icon}
                                sx={{
                                    width: 24,
                                    height: 24,
                                    color: 'primary.main',
                                    backgroundColor: 'white',
                                }}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            primary={`${ItemText} ${numberFormatter.format(
                                item.total
                            )}`}
                            secondary={`MPASS ${numberFormatter.format(
                                item.mpass
                            )} (${Math.round(
                                (item.mpass / item.total) * 100
                            )} %) | Finnlectura ${numberFormatter.format(
                                item.finnlectura
                            )}  (${Math.round(
                                (item.finnlectura / item.total) * 100
                            )} %) `}
                        />
                    </ListItem>
                );
            })}
        </List>
    );
};

const NewUsersByRolesGraph = (data: NewUser[]) => {
    const translate = useTranslate();
    if (!data[0]) {
        return (
            <Alert severity="warning" sx={{ padding: 10 }}>
                {translate('statistics.nodata')}
            </Alert>
        );
    }

    const xSeriesMPass = {
        data: data.map(item => item.mpass),
        label: 'MPASS',
        stack: translate('statistics.fields.all'),
    };

    const xSeriesFinnlectura = {
        data: data.map(item => item.finnlectura),
        label: 'Finnlectura',
        stack: 'Uudet käyttäjät',
    };

    const xSeriesTotal = {
        data: data.map(item => item.total),
        label: 'Kaikki',
    };

    const xSeries = [xSeriesTotal, xSeriesMPass, xSeriesFinnlectura];

    console.log(xSeries);
    const xAxis = data.map(item => item.app);

    return (
        <BarChart
            height={400}
            series={xSeries}
            xAxis={[{ data: xAxis, scaleType: 'band' }]}
        />
    );
};

const NewUsers = (props: { data: NewUser[] | null } & Handler & Filter) => {
    const translate = useTranslate();
    const { data, handler, mode, from, to } = props;
    const totalSessions = numberFormatter.format(
        data.reduce((sum, item) => sum + item.total, 0)
    );
    const dateFrom = resolveDate(from);
    const dateTo = resolveDate(to);
    const days = daysBetweenDates(dateFrom, dateTo);
    const title = translate('statistics.newusers.title');
    const tooltip = translate('statistics.newusers.tooltip', { day: days });

    return (
        <>
            {createElement(handler, {
                title: days === 1 ? title : tooltip,
                subtitle: String(totalSessions),
                tooltip: days === 1 ? tooltip : null,
                icon: appIcons.all,
                children: (() => {
                    switch (mode) {
                        case 'List':
                            return NewUsersByRoles(data);
                        case 'Graph':
                            return NewUsersByRolesGraph(data);
                        default:
                            return null;
                    }
                })(),
            })}
        </>
    );
};
export default NewUsers;
