import React from 'react';
import { useRecordContext } from 'react-admin';
import get from 'lodash/get';

interface Props {
    source: string;
    label?: string;
}

const CustomServicesField = (props: Props) => {
    const { source = "services", label = "" } = props;
    const record = useRecordContext();
    
    return (
        <div>
        {record[source].map((service) => (
            service != 'crm' && service != 'fl_opettaja') ? 
                <span key={`service_${service}`} style={{textDecoration:'none',color: '#fff',fontSize:12,padding: '1px 6px',borderRadius: 3,marginRight:3,height: 15,marginLeft:3,background: get(record,'mpass.error') && service === 'mpass' ? 'red': 'rgb(96, 125, 139, .5)'}} >{`${service.replace('opepalvelu','OOP').replace('finnlectura','FL').toUpperCase()}`}</span> : <span/>
        )}
        </div>
    );
};

export default CustomServicesField;
