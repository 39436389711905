import * as React from 'react';
import { useState } from 'react';
import { useRefresh, useEditContext, useListContext, useNotify, useUnselectAll, Confirm, useUpdateMany, useDataProvider, useCreate } from 'react-admin';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const CustomAddSubscriptionButton = () => {

    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [validFrom, setValidFrom] = useState('1.8.2024');
    const [validUntil, setVallidUntil] = useState('31.7.2025');
    const [licenseType, setLicenseType] = useState('1');
    const [licenseCount, setLicenseCount] = useState('1');

    //const [addCount, setAddCount] = useState('0');

    const refresh = useRefresh();
    const parent = useEditContext();
    const list = useListContext();
    const notify = useNotify();
    const url = `subscription`;

    const unselectAll = useUnselectAll(list.resource);
    const dataProvider = useDataProvider();

    // const [updateMany, { isLoading }] = useUpdateMany(
    //     url,
    //     { ids: list.selectedIds },
    //     {
    //         onSuccess: () => {
    //             refresh();
    //             unselectAll();
    //         },
    //         onError: error => notify(`Virhe lisättäessä: ${error}`, { type: 'warning' }),
    //     }
    // );

    const handleClick = () => setOpen(true);
    
    const handleDialogClose = () => {
        refresh();
        unselectAll();
        setOpen(false);
    }

    //var createData = {}; 

    // const [create, { isLoading }] = useCreate(
    //     url,
    //     { data: {
    //         subscriber_guid:parent.record.parent_group[0].guid,
    //         product_guid: list.selectedIds[0],
    //         license_count: parseInt(licenseCount),
    //         valid_from: validFrom,
    //         valid_until: validUntil,
    //         type: parseInt(licenseType)
    //     } },
    //     {
    //         onSuccess: () => {
    //             //console.log(createData);
    //             console.log(isLoading);
    //             //refresh();
    //             //unselectAll();
    //         },
    //         onError: error => notify(`Virhe lisättäessä: ${error}`, { type: 'warning' }),
    //     }
    // );

    const handleConfirm = () => {
        //updateMany();
        
        //console.log(list.selectedIds.length.toString());
        // setAddCount(list.selectedIds.length.toString());
        // console.log(addCount);
        list.selectedIds.map((id, ind) => {
            // console.log({
            //     subscriber_guid:parent.record.parent_group[0].guid,
            //     product_guid: id,
            //     license_count: parseInt(licenseCount),
            //     valid_from: validFrom,
            //     valid_until: validUntil,
            //     type: parseInt(licenseType)});

            // createData = {
            //     subscriber_guid:parent.record.parent_group[0].guid,
            //     product_guid: id,
            //     license_count: parseInt(licenseCount),
            //     valid_from: validFrom,
            //     valid_until: validUntil,
            //     type: parseInt(licenseType)
            // };
            //create();
            //console.log(list.selectedIds.length - ind);
            setIsLoading(true);
            dataProvider.create(
                url,
                { data: {
                        subscriber_guid:parent.record.parent_group[0].guid,
                        product_guid: id,
                        license_count: parseInt(licenseCount),
                        valid_from: validFrom,
                        valid_until: validUntil,
                        type: parseInt(licenseType),
                        return_data: true,
                    } 
                }
            ).then(data => {
                //console.log("onnistui");
                notify(`Lisenssi tuotteelle XXX lisätty`, { type: 'success' });
            }).catch(error => {
                notify(`Virhe lisättäessä: ${error}`, { type: 'warning' });
            }).finally(() => {
                console.log("finally");
                setIsLoading(false);
                if (list.selectedIds.length <= (ind + 1)) {
                    refresh();
                    unselectAll();
                }
                // setAddCount((parseInt(addCount)-1).toString());
                //console.log(addCount);
            });
        });
        //tää ei toimi, pitää hoitaa tuo refresh vasta, kun kaikki lisätty
        //refresh();
        //unselectAll();
        setOpen(false);
    };
    
    return (
        <>
            <TextField id="license_count" label="Määrä" variant="filled" size="small" value={licenseCount} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setLicenseCount(event.target.value);}}  />
            <TextField id="valid_from" label="Mistä" variant="filled" size="small" value={validFrom} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setValidFrom(event.target.value);}}  />
            <TextField id="valid_until" label="Mihin" variant="filled" size="small" value={validUntil} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setVallidUntil(event.target.value);}} />
            <Select id="license_type" value={licenseType} label="Lisenssityyppi" variant="filled" size="small" onChange={(event: SelectChangeEvent) => {setLicenseType(event.target.value as string);}}>
                <MenuItem value={0}>Oppilaslisenssi</MenuItem>
                <MenuItem value={1}>Koululisenssi</MenuItem>
            </Select>
            <Button onClick={handleClick} disabled={isLoading} color='success'>Lisää</Button>
            <Confirm
                isOpen={open}
                loading={isLoading}
                title="Lisää valitut tilaukset"
                content={"Haluatko varmasti lisätä valitsemasi tuotteet alituotteiksi tuotteelle " + parent.record.name + "?"}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </>
    );
};

export default CustomAddSubscriptionButton;
