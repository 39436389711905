import * as React from 'react';
import { Card, CardContent } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOnOutlined';
import MailIcon from '@mui/icons-material/MailOutline';
import LocalOfferIcon from '@mui/icons-material/LocalOfferOutlined';
import { FilterList, FilterListItem, FilterLiveSearch, SavedQueriesList } from 'react-admin';
//import { SavedQueriesList } from '@react-admin/ra-preferences';
import {
    endOfYesterday,
    startOfWeek,
    subWeeks,
    startOfMonth,
    subMonths,
} from 'date-fns';

import SubscriberIcon from '@mui/icons-material/Domain'
import LanguageIcon from '@mui/icons-material/Language';
import ApplicationIcon from '@mui/icons-material/Web';
import TypeIcon from '@mui/icons-material/LibraryBooks';
import StatusIcon from '@mui/icons-material/CheckCircle';
import OnlineIcon from '@mui/icons-material/CellTower';

import CustomFilterList from '../controls/CustomFilterList';
import CustomFilterSearch from '../controls/CustomFilterSearch';

//import segments from '../segments/data';

const AnnouncementListAside = () => (
    <Card
        sx={{
            display: {
                xs: 'none',
                md: 'block',
            },
            order: -1,
            flex: '0 0 15em',
            mr: 2,
            mt: 4.5,
            alignSelf: 'flex-start',
        }}
    >
        <CardContent sx={{ pt: 1 }}>
            <FilterLiveSearch source="q" variant='outlined' />
            <SavedQueriesList />
            <CustomFilterList
                url="extralist/announcement/online_statuses"
                label="resources.announcement.filters.online_status"
                source="online_status"
                icon={<OnlineIcon />}
            />
            <CustomFilterList
                url="extralist/announcement/statuses"
                label="resources.announcement.filters.status"
                source="status"
                icon={<StatusIcon />}
            />
            <CustomFilterList
                url="extralist/announcement/applications"
                label="resources.announcement.filters.applications"
                source="applications"
                icon={<ApplicationIcon />}
            />
            <CustomFilterList
                url="extralist/announcement/languages"
                label="resources.announcement.filters.languages"
                source="language"
                icon={<LanguageIcon />}
            />
            <CustomFilterList
                url="extralist/announcement/types"
                label="resources.announcement.filters.announcement_types"
                source="announcement_type"
                icon={<TypeIcon />}
            />
            {/* Pitäiskö näiden filtteröidä sen perusteella näkyykö??? */}
            {/* <CustomFilterList
                className="scrollablelist"
                url="extralist/school/types"
                label="resources.school.filters.types"
                source="types"
                icon={<TypeIcon />}
            />  */}
            {/* <CustomFilterList
                url="extralist/school/type_groups"
                label="resources.school.filters.type_groups"
                source="typegroups"
                icon={<TypeIcon />}
            /> */}
        </CardContent>
    </Card>
);

export default AnnouncementListAside;
