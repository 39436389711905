import * as React from 'react';
import { useState } from 'react';
import { useRefresh, useEditContext, useListContext, useDeleteMany, useNotify, useUnselectAll, Confirm } from 'react-admin';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';

interface Props {
    resource_guid: string;
}

const CustomDBDeleteButton = (props: Props) => {

    const [open, setOpen] = useState(false);
    const refresh = useRefresh();
    const parent = useEditContext();
    const list = useListContext();
    const notify = useNotify();
    
    //const url = `${parent.resource}/${parent.record.guid}/${list.resource}/permanent`;
    const url = `${list.resource}/permanentdelete`;
    
    //console.log(url);
    //console.log(props.resource_guid);

    //const unselectAll = useUnselectAll(list.resource);

    const [deleteMany, { isLoading }] = useDeleteMany(
        url,
        { ids: [props.resource_guid] },
        {
            onSuccess: () => {
                refresh();
                //unselectAll();
            },
            onError: error => notify(`Virhe poistettaessa: ${error}`, { type: 'warning' }),
        }
    );

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => {
        refresh();
        //unselectAll();
        setOpen(false);
    }

    const handleConfirm = () => {
        deleteMany();
        setOpen(false);
    };

    return (
        <>
            <Button onClick={handleClick} disabled={isLoading} color='warning'><DeleteIcon /></Button>
            <Confirm
                isOpen={open}
                loading={isLoading}
                title="Poista valittu lisenssi"
                content={"Haluatko varmasti poistaa valitsemasi lisenssin pysyvästi? Tätä toimintoa ei voi peruuttaa!"}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </>
    );
};

export default CustomDBDeleteButton;
