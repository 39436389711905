import {
    FC,
    useState,
    createElement,
    useEffect,
    useContext,
    useCallback,
} from 'react';
import { useDataProvider, useTranslate } from 'react-admin';
import CircularProgress from '@mui/material/CircularProgress';
import { EditContext } from 'react-admin';
import { useQuery } from '@tanstack/react-query';
import ActiveSessions from '../../components/statistics/ActiveSessions';
import ActiveUsers from '../../components/statistics/ActiveUsers';
import NewUsers from '../../components/statistics/NewUsers';
import ProductTransitions from '../../components/statistics/ProductTransitions';
import UserEvents from '../../components/statistics/UserEvents';
import { Filter, Modes } from '../../components/statistics/types';
import { Alert, Box } from '@mui/material';
import { Button, ButtonGroup } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { fi } from 'date-fns/locale';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import { Grid, Stack } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import { resolveDateAndLabel } from '../../components/statistics/common';

const Config = {
    activeSessions: {
        url: '/statistics/activesessions',
        control: ActiveSessions,
    },
    activeUsers: {
        url: '/statistics/activeusers',
        control: ActiveUsers,
    },
    newUsers: {
        url: '/statistics/newusers',
        control: NewUsers,
    },
    userEvents: {
        url: '/statistics/userevents',
        control: UserEvents,
    },
    productTransitions: {
        url: '/statistics/producttransitions',
        control: ProductTransitions,
    },
};

type Props = {
    statisticKey: keyof typeof Config;
    handler: FC<any>;
    mode: Modes;
    only_data?: boolean;
};

const renderComponent = (
    control: FC<any>,
    data: any,
    handler: FC<any>,
    mode: string,
    only_data: boolean,
    filter: any
) => {
    return createElement(control, {
        ...data,
        handler,
        mode,
        only_data,
        ...filter,
    });
};

const CustomStatistics = (props: Props & Filter) => {
    const {
        statisticKey,
        handler,
        mode,
        only_data,
        from,
        to,
        group_by,
        limit,
        pagination,
        include_search,
        exclude_search,
        search,
        showFilter = false,
        ttl,
    } = props;
    const dataProvider = useDataProvider();
    const parent = useContext(EditContext);
    const translate = useTranslate();
    let config = { ...Config[statisticKey] };
    const [filter, setFilter] = useState<Filter>(null);
    const [allData, setAllData] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>('');
    const shwoPagination = data => {
        if (!pagination) return false;
        if (data.data.length < filter.limit) return false;
        return true;
    };

    const updateFilter = useCallback(() => {
        let f: Filter = { from };

        if (parent) {
            if (statisticKey === 'userEvents') {
                f.user_guid = parent.record.guid;
            } else if (parent.resource === 'group') {
                f.group_guid = parent.record.guid;
            } else if (parent.resource === 'school') {
                f.school_guid = parent.record.guid;
            } else if (parent.resource === 'product') {
                f.product_guid = parent.record.guid;
            }
        }

        if (group_by) {
            f.group_by = group_by;
        }

        if (limit) {
            f.limit = limit;
        }

        if (pagination) {
            f.pagination = pagination;
        }

        if (include_search) {
            f.include_search = include_search;
        }
        if (exclude_search) {
            f.exclude_search = exclude_search;
        }

        f.showFilter = showFilter;
        f.search = search;
        f.ttl = ttl;
        f.to = to;

        setFilter(f);
    }, [
        from,
        to,
        group_by,
        limit,
        pagination,
        parent,
        statisticKey,
        include_search,
        exclude_search,
        showFilter,
        search,
        ttl,
    ]);

    useEffect(() => {
        updateFilter();
    }, [updateFilter]);

    const nextPage = () => {
        setLoading(true);
        setFilter(prevFilter => ({
            ...prevFilter,
            search_after: allData.data[allData.data.length - 1].search_after,
        }));
    };

    const params = `filter=${JSON.stringify(filter)}`;
    const url = config.url;

    const { data, error, isPending } = useQuery({
        queryKey: [url, params],
        queryFn: () => dataProvider.getAny(url, params),
        enabled: !!filter,
        staleTime: Infinity, // Cache the data indefinitely
    });

    const handleIncludeSearch = (e, values: string[]) => {
        setFilter(prevFilter => {
            let include_search = prevFilter.include_search;
            if (e.target.checked) {
                include_search.push(...values);
            } else {
                include_search = include_search.filter(
                    item => !values.includes(item)
                );
            }
            return { ...prevFilter, include_search, search_after: null };
        });
    };

    const handleSearchChange = e => {
        setSearchValue(e.target.value);
    };

    const handleSearchClick = () => {
        setFilter(prevFilter => ({
            ...prevFilter,
            search: searchValue,
            search_after: null,
        }));
    };

    useEffect(() => {
        if (!data) return;
        setAllData(prevData => {
            if (!prevData || !filter.search_after) return data;
            return { data: [...prevData.data, ...data.data] };
        });
        setLoading(false);
    }, [data]);

    const createFormControlLabel = (label: string, keywords: string[]) => (
        <FormControlLabel
            control={
                <Checkbox
                    checked={keywords.every(keyword =>
                        filter.include_search.includes(keyword)
                    )}
                    onChange={e => handleIncludeSearch(e, keywords)}
                />
            }
            label={label}
        />
    );

    if (error) {
        return (
            <Alert severity="error" sx={{ padding: 10 }}>
                {error.message}
            </Alert>
        );
    }

    if (isPending || !data || !allData || !filter) {
        return (
            <Box
                sx={{
                    padding: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    vh: '100%',
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            {showFilter && (
                <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={fi}
                >
                    <Grid container spacing={2}>
                        {statisticKey === 'userEvents' && (
                            <Grid item xs={12} sm={5}>
                                {createFormControlLabel('Tunnistautuminen', [
                                    'kirjautuminen',
                                    'logout2',
                                ])}
                                {createFormControlLabel('Tuotesiirtymät', [
                                    'getMaterialUrl',
                                    'createRedirect',
                                    'redirect',
                                ])}
                                {createFormControlLabel('Ryhmään liittyvät', [
                                    'ryhmä',
                                    'koulu',
                                ])}
                            </Grid>
                        )}
                        <Grid item xs={12} sm={3}>
                            <Stack direction="row">
                                <TextField
                                    id="filled-basic"
                                    label="Vapaa haku"
                                    helperText="Haussa voi käyttää *-merkkiä"
                                    variant="standard"
                                    fullWidth={true}
                                    value={searchValue}
                                    onChange={handleSearchChange}
                                />
                                <IconButton
                                    type="button"
                                    sx={{ p: '10px' }}
                                    aria-label="search"
                                    onClick={handleSearchClick}
                                >
                                    <SearchIcon />
                                </IconButton>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Stack direction="column" spacing={2}>
                                <Stack direction="row">
                                    <DateTimePicker
                                        label={
                                            resolveDateAndLabel(filter.from)
                                                .label
                                        }
                                        disableFuture={true}
                                        minDate={new Date('2024-08-01')}
                                        value={
                                            resolveDateAndLabel(filter.from)
                                                .date
                                        }
                                        onAccept={date => {
                                            setFilter(prevFilter => ({
                                                ...prevFilter,
                                                from: date.toISOString(),
                                                search_after: null,
                                            }));
                                        }}
                                    />
                                    <DateTimePicker
                                        label={
                                            resolveDateAndLabel(filter.to).label
                                        }
                                        disableFuture={true}
                                        minDate={new Date('2024-08-01')}
                                        value={
                                            resolveDateAndLabel(filter.to).date
                                        }
                                        onAccept={date => {
                                            setFilter(prevFilter => ({
                                                ...prevFilter,
                                                to: date.toISOString(),
                                                search_after: null,
                                            }));
                                        }}
                                    />
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                </LocalizationProvider>
            )}
            {renderComponent(
                config.control,
                allData,
                handler,
                mode,
                only_data,
                filter
            )}
            {shwoPagination(data) && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '1em',
                    }}
                >
                    <ButtonGroup
                        variant="contained"
                        aria-label="outlined primary button group"
                    >
                        <Button onClick={nextPage} disabled={loading}>
                            {translate('statistics.fields.show_more')}
                        </Button>
                    </ButtonGroup>
                </Box>
            )}
        </>
    );
};

export default CustomStatistics;
