import { DataProvider } from 'react-admin';

const addInfraMethods = (dataProvider: DataProvider, httpClient) => {
    return {
        ...dataProvider,

        getAny: async (resource, params) => {
            //let query = (params != undefined && params != '') ? `?${stringify(params)}` : '';
            const query =
                params !== undefined && params !== '' ? `?${params}` : '';

            const url = `${process.env.REACT_APP_INFRA_API}/${resource}${query}`;
            //console.log('getAny:' + url);

            const { json } = await httpClient(url);
            return { data: json };
        },
    };
};

export default addInfraMethods;
