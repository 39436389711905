import { useListContext, useTranslate } from 'react-admin';
import {
    Button,
    Toolbar,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Stack,
} from '@mui/material';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { useState } from 'react';

const CustomPagination = () => {
    const {
        page,
        hasPreviousPage,
        setPage,
        data,
        perPage,
        setPerPage,
        isPending,
    } = useListContext();
    const translate = useTranslate();
    const [pageSize, setPageSize] = useState(perPage);

    if (isPending || !data) return null;

    const total = data.length;
    const hasNextPage = total >= perPage;

    const handlePageSizeChange = event => {
        const newSize = event.target.value;
        setPageSize(newSize);
        setPerPage(newSize);
    };

    if (!hasPreviousPage && !hasNextPage) return null;

    return (
        <Toolbar
            style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
            }}
        >
            <Stack direction="row" spacing={2}>
                <InputLabel id="page-size">
                    {translate('ra.navigation.page_rows_per_page')}
                </InputLabel>
                <FormControl
                    variant="standard"
                    size="small"
                    sx={{ minWidth: 'auto', width: 'fit-content' }}
                >
                    <Select value={pageSize} onChange={handlePageSizeChange}>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                    </Select>
                </FormControl>

                {hasPreviousPage && (
                    <Button
                        key="previous"
                        onClick={() => setPage(page - 1)}
                        startIcon={<ChevronLeft />}
                    >
                        {translate('ra.navigation.previous')}
                    </Button>
                )}
                {hasNextPage && (
                    <Button
                        key="next"
                        onClick={() => setPage(page + 1)}
                        endIcon={<ChevronRight />}
                    >
                        {translate('ra.navigation.next')}
                    </Button>
                )}
            </Stack>
        </Toolbar>
    );
};

export default CustomPagination;
