import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import FaceIcon from '@mui/icons-material/Face';
import SchoolIcon from '@mui/icons-material/School';
import Face5Icon from '@mui/icons-material/Face5';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import History from '@mui/icons-material/History';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import DomainIcon from '@mui/icons-material/Domain';
import Diversity1 from '@mui/icons-material/Diversity1';
import ms, { StringValue } from 'ms';

export const appIcons = {
    student: FaceIcon,
    teacher: SchoolIcon,
    pupil: Face5Icon,
    all: PeopleAltIcon,
    product: LibraryBooksIcon,
    userevent: History,
    subscription: SubscriptionsIcon,
    school: DomainIcon,
    group: Diversity1,
};

export const numberFormatter = new Intl.NumberFormat('fi-FI', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    useGrouping: true,
});

export const dateFormatter = new Intl.DateTimeFormat('fi-FI', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
});

export const dateTimeFormatter = new Intl.DateTimeFormat('fi-FI', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
});

export const StatsTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[3],
        fontSize: 12,
    },
}));

export const daysBetweenDates = (dateFrom: Date, dateTo: Date): number => {
    const diffInMs =
        (dateTo?.getTime() || Date.now()) - (dateFrom?.getTime() || 0);
    const msPerDay = 1000 * 60 * 60 * 24;
    const diffInDays = Math.round(diffInMs / msPerDay);
    return diffInDays;
};

export const resolveDays = (period: StringValue): string => {
    return ms(period) ? ms(ms(period), { long: true }).split(' ')[0] : '';
};

export const resolveDate = (dateOrPeriod: string): Date => {
    if (!dateOrPeriod) {
        return new Date();
    }
    const days = resolveDays(dateOrPeriod);
    const date = new Date(dateOrPeriod);
    return days
        ? new Date(Date.now() - parseInt(days) * 24 * 60 * 60 * 1000)
        : date;
};

export const resolveDateAndLabel = (
    dateOrPeriod: string
): { date: Date; label: string } => {
    if (!dateOrPeriod) {
        return {
            date: null,
            label: dateFormatter.format(Date.now()),
        };
    }
    const days = resolveDays(dateOrPeriod);
    const date = isNaN(new Date(dateOrPeriod)?.getTime())
        ? null
        : new Date(dateOrPeriod);
    return {
        date: days ? null : date,
        label: days ? dateFormatter.format(resolveDate(days)) : 'Mistä',
    };
};

export const isUuid = (uuid: string): boolean => {
    return /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(
        uuid
    );
};

const currentYear = new Date().getFullYear();
export const defaultPeriods = [
    {
        label: 'statistics.periods.day',
        value: '1day',
        default: true,
        group_by: 'hour',
    },
    {
        label: 'statistics.periods.week',
        value: '1week',
        default: false,
        group_by: 'day',
    },
    {
        label: 'statistics.periods.month',
        value: '30days',
        default: false,
        group_by: 'day',
    },
    {
        label: 'statistics.periods.startperiod',
        value: `${currentYear}-08-01`,
        default: false,
        group_by: 'month',
    },
];
