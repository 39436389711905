import * as React from 'react';
import { List, Datagrid, TextField, DateField, NumberField, TextInput, useTranslate, useEditContext, ListProps, FunctionField, Pagination } from 'react-admin';
import CustomEmpty from './CustomEmpty';
import CustomSelectInput from './CustomSelectInput';
import get from 'lodash/get';
import ProductFullNameField from '../product/ProductFullNameField';

interface Props extends ListProps {
    bulkActionButtons?: false | React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
    sortable?: boolean;
    showExtra?: any;
    hideFields?: any;
    customFilters?: any;
    refreshKey?: string;
    children: React.ReactNode;
}

const CustomSubscriptionList = (props: Props = {
    sortable: true,
    showExtra: [],
    hideFields: [],
    customFilters: [],
    refreshKey: 'refreshAll',
    children: null,
}) => {

    const translate = useTranslate();

    const parent = useEditContext();
    //const [filterListSettings, setFilterListSettings] = useStore(`${props.storeKey}_q`, "");
    //console.log(props.storeKey);
    var filter = {};
    //const filter = props.customFilters?.includes("subscriber_code") ? {"subscriber_guid": parent.record.parent_group[0].guid} :  undefined;
    if (props.customFilters?.includes("subscriber_code"))
        filter["subscriber_guid"] = parent.record.parent_group[0].guid;
    if (props.customFilters?.includes("pupil"))
        filter["type"] = 0;
    else if (props.customFilters?.includes("teacher"))
        filter["type"] = 1;

//    filter["isvalid"] = "current";


    return (
        <List pagination={<Pagination rowsPerPageOptions={[10,25,50,100,200]} />} {...props} actions={false} filter={filter} filters={props.showExtra?.includes("search") ? [<CustomSelectInput url="extralist/subscription/isvalid" source="isvalid" label={translate('resources.subscription.filters.isvalid')} filter="isvalid" alwaysOn />, <TextInput label={translate('pos.search')} source="q" size="small" alwaysOn />] : undefined} empty={<CustomEmpty />} emptyWhileLoading={true}>
            <Datagrid bulkActionButtons={props.bulkActionButtons} rowClick="edit">
                {/*!props.hideFields?.includes("name") && <TextField source="product.name" label={translate('resources.product.fields.name')} sortable={props.sortable} />*/}
                {!props.hideFields?.includes("name") && <ProductFullNameField source="product" key="product.name" createLink={true} useIcon={false} sortable={props.sortable} />}
                {!props.hideFields?.includes("isbn") && <TextField source="product.public.materialstorage.isbn" label={translate('resources.product.fields.public.materialstorage.isbn')} sortable={props.sortable} />}
                {!props.hideFields?.includes("license_count") && <NumberField source="license_count" label={translate('resources.subscription.fields.license_count')} sortable={props.sortable} />}
                {!props.hideFields?.includes("used_license_count") && <NumberField source="used_license_count" label={translate('resources.subscription.fields.used_license_count')} sortable={props.sortable} />}
                {!props.hideFields?.includes("valid_from") && <DateField source="valid_from" showTime={false} locales="fi-FI" label={translate('resources.subscription.fields.valid_from')} sortable={props.sortable} />}
                {!props.hideFields?.includes("valid_until") && <DateField source="valid_until" showTime={false} locales="fi-FI" label={translate('resources.subscription.fields.valid_until')} sortable={props.sortable} />}
                {!props.hideFields?.includes("orderlink") && <FunctionField key="guidlink" render={record =>  get(record,'guid','') !== '' ? <a style={{textDecoration: 'none', backgroundColor: '#afbec5',fontSize:12,color: 'rgb(255, 255, 255)',padding: '1px 6px',borderRadius: 3,background: 'rgba(96, 125, 139, 0.5)',height: 15,marginLeft:3}} title={`${get(record,'guid')}`} href={`#/subscription/${get(record,'guid')}`}>Tilaukset</a> : ''} />}
                {/* <FunctionField render={record =>  get(record,'services',[]).map(service => !['opiskelija','opepalvelu','oppilaspalvelu'].includes(service) ? <span key={`service_${service}`} style={{textDecoration:'none',color: '#fff',padding: '2px 6px',borderRadius: 5,background: 'rgb(96, 125, 139, .5)'}} >{`${service}`}</span> : '')}/> */}
                {/* {!props.hideFields?.includes("orderlink") && <CustomUrlButton label={translate('resources.subscription.name', { smart_count: 1 })} gotourl={true} icon={<MovingIcon style={{fontSize: 'large'}} />} url={`subscription/#guid#`} urlparams={['guid']} />} */}
            </Datagrid>
        </List>
    );
};

export default CustomSubscriptionList;
