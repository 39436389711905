import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
    SearchInput,
    useTranslate,
    useEditContext,
    ListProps,
} from 'react-admin';
import CustomEmpty from './CustomEmpty';
import RoleNameField from '../user/RoleNameField';
import SubjectListField from '../user/SubjectListField';
import PeopleIcon from '@mui/icons-material/People';
import CustomLogAsTeacherButton from './CustomLogAsTeacherButton';
import CustomLogAsStudentButton from './CustomLogAsStudentButton';

interface Props extends ListProps {
    bulkActionButtons?:
        | false
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | undefined;
    sortable?: boolean;
    showExtra?: any;
    hideFields?: any;
    customFilters?: any;
    refreshKey?: string;
    storeKey?: string;
    emptyIcon?: any;
}

//const customRowClick = (id: any, basePath: any, record: any) => {console.log("customRowClick", id, basePath, record); return false;};
//return "https://oppimateriaalit-aspa.otava.fi/#/pupil";

const CustomUserList = ({
    bulkActionButtons,
    sortable = true,
    showExtra = [],
    hideFields = [],
    customFilters = [],
    refreshKey = 'refreshAll',
    storeKey = 'customUserList',
    emptyIcon,
    ...props
}: Props) => {
    const translate = useTranslate();

    const parent = useEditContext();
    var filter = {};
    var icon = PeopleIcon;

    if (props.filter) filter = props.filter;

    if (emptyIcon) icon = emptyIcon;
    //const filter = props.customFilters?.includes("subscriber_code") ? {"subscriber_guid": parent.record.parent_group[0].guid} :  undefined;
    if (customFilters?.includes('group')) filter['group'] = parent.record.guid;

    if (customFilters?.includes('parent_group'))
        filter['parent_group'] = parent.record.guid;
    //skip_parent
    if (customFilters?.includes('skip_parent')) filter['skip_parent'] = true;

    return (
        <List
            {...props}
            actions={false}
            filter={filter}
            filters={
                showExtra?.includes('search')
                    ? [<SearchInput source="q" size="small" alwaysOn />]
                    : undefined
            }
            empty={<CustomEmpty icon={icon} />}
        >
            <Datagrid
                bulkActionButtons={bulkActionButtons}
                rowClick="edit" /* rowClick={(id: any, basePath: any, record: any) => {window.open("https://oppimateriaalit-aspa.otava.fi/#/"+basePath+"/"+id, "_blank");return false;}} */
            >
                {!hideFields?.includes('lastname') && (
                    <TextField
                        source="public.lastname"
                        label={translate('resources.userlist.fields.lastname')}
                        sortable={sortable}
                    />
                )}
                {!hideFields?.includes('middlename') && (
                    <TextField
                        source="public.middlename"
                        label={translate(
                            'resources.userlist.fields.middlename'
                        )}
                        sortable={sortable}
                    />
                )}
                {!hideFields?.includes('firstname') && (
                    <TextField
                        source="public.firstname"
                        label={translate('resources.userlist.fields.firstname')}
                        sortable={sortable}
                    />
                )}
                {!hideFields?.includes('code') && (
                    <TextField
                        source="public.code"
                        label={translate('resources.userlist.fields.code')}
                        sortable={sortable}
                    />
                )}
                {!hideFields?.includes('username') && (
                    <TextField
                        source="username"
                        label={translate('resources.userlist.fields.username')}
                        sortable={sortable}
                    />
                )}
                {!hideFields?.includes('email') && (
                    <TextField
                        source="public.email"
                        label={translate('resources.userlist.fields.email')}
                        sortable={sortable}
                    />
                )}
                {!hideFields?.includes('role') && (
                    <RoleNameField
                        label={translate('resources.userlist.fields.role')}
                        sortable={sortable}
                    />
                )}
                {!hideFields?.includes('subjects') && (
                    <SubjectListField
                        label={translate('resources.userlist.fields.subjects')}
                        sortable={sortable}
                    />
                )}
                {!hideFields?.includes('loginteacher') && (
                    <CustomLogAsTeacherButton />
                )}
                {!hideFields?.includes('loginstudent') && (
                    <CustomLogAsStudentButton />
                )}
            </Datagrid>
        </List>
    );
};

export default CustomUserList;
