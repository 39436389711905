import * as React from 'react';
import { FC, useState } from 'react';

//import { useFormState } from 'react-final-form';
import { useDataProvider } from 'react-admin';
import Switch from '@mui/material/Switch';
import CircularProgress from '@mui/material/CircularProgress';
import { useRecordContext, useEditContext } from 'react-admin';
import lodash from 'lodash';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
//import { FieldProps } from '../../types';

interface Props {
    source: string;
    defaultValue?: boolean;
    url: string;
    urlparams?: any;
    label?: string;
}

const CustomUrlBooleanInput: FC<Props> = ({
    source,
    defaultValue = false,
    url,
    urlparams = [],
    label = "",
    //...props
}) => {

    const record = useRecordContext();
    const params = '';
    const dataProvider = useDataProvider();
  
    const checked =
        lodash.get(record, source) != undefined
            ? lodash.get(record, source)
            : defaultValue;

    const parent = useEditContext(); //tällä haetaan parent
    urlparams.forEach(param => {
        url = url.replace(
            `##${param}##`,
            lodash.get(parent.record, param) != undefined
                ? lodash.get(parent.record, param)
                : ''
        );
    });

    urlparams.forEach(param => {
        url = url.replace(
            `#${param}#`,
            lodash.get(record, param) != undefined
                ? lodash.get(record, param)
                : ''
        );
    });
    //});

    const [val, setVal] = useState(checked);
    const [loading, setLoading] = useState(false);
    //const refresh = useRefresh();

    const handleOnChange: any = () => {
        setLoading(true);
        const newVal = !val;
        setVal(newVal);
        dataProvider
            .getAny(url + newVal, params)
            .then(({}) => {
                //console.log(data);
                //refresh();
                //setCustomChoices(data);
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                //refresh();
                setLoading(false);
            });
    };

    //console.log("tämä ses val: "+val);
    if (loading) return <CircularProgress color="primary" style={{width: '1.5em', height: '1.5em'}} />;

    return (
        <Switch
            key={`ali_${lodash.get(record, 'guid')}`}
            checked={val}
            onChange={handleOnChange}
            disabled={loading}
        />
    );
};

export default CustomUrlBooleanInput;
