import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import SubscriptionList from './SubscriptionList';
import SubscriptionEdit from './SubscriptionEdit';
// import SubscriptionCreate from './SubscriptionCreate';

export default {
    list: SubscriptionList,
    // create: SubscriptionCreate,
    edit: SubscriptionEdit,
    icon: SubscriptionsIcon,
};