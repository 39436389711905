import * as React from 'react';
import {
    Edit,
    NumberInput,
    TextInput,
    TabbedForm,
    FormTab,
    SaveButton,
    Toolbar,
    DateInput,
    TextField,
    BooleanField,
    useListContext,
    FunctionField,
    ArrayField,
    Labeled,
    ReferenceField,
    DateField,
} from 'react-admin';
import {
    required,
    minValue,
    maxValue,
    minLength,
    maxLength,
    number,
    email,
    regex,
    choices,
    useRefresh,
    useRecordContext,
    useResourceContext,
    Datagrid,
    useStoreContext,
    useEditContext,
    useTranslate,
} from 'react-admin';
import { Grid, Box, Typography } from '@mui/material';

import {
    CustomRadioButtonGroupInput,
    CustomBulkDeleteButton,
    CustomUserLicenseList,
    CustomLogEventList,
    CustomCheckboxGroupInput,
    CustomSubscriptionList,
    CustomUserList,
    CustomProductList,
    CustomAddSubscriptionButton,
    CustomGroupList,
} from '../controls';

import user from '../user';

import {
    LibraryBooks,
    Article,
    Podcasts,
    Attachment,
    DataObject,
    History,
    Person,
    ReceiptLong,
    TransferWithinAStation,
    Diversity1,
    Subscriptions,
    School,
    Face,
    Face5,
} from '@mui/icons-material';

const EditToolbar = () => {
    return (
        <Toolbar>
            <> </>
            {/* <SaveButton /> */}
        </Toolbar>
    );
};

const GroupEdit = () => {
    const translate = useTranslate();
    const record = useRecordContext();
    const resource = useResourceContext();
    //console.log("GroupEdit", resource, record);

    return (
        <Edit redirect={false} mutationMode="optimistic">
            <TabbedForm toolbar={<EditToolbar />}>
                {/* Tähän perustiedot */}
                <FormTab
                    label="resources.group.tabs.details"
                    icon={<Article />}
                >
                    <Grid container width={{ xs: '100%' }} spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Box
                                display={{ xs: 'block', sm: 'flex' }}
                                sx={{ marginBottom: '10px' }}
                            >
                                <Labeled
                                    label={translate(
                                        'resources.group.fields.name'
                                    )}
                                >
                                    <FunctionField
                                        render={record =>
                                            record.public.archived &&
                                            record.public.archived == true
                                                ? record.name + ' (Arkistoitu)'
                                                : record.name
                                        }
                                    />
                                </Labeled>
                            </Box>
                            <Box
                                display={{ xs: 'block', sm: 'flex' }}
                                sx={{ marginBottom: '10px' }}
                            >
                                <Labeled>
                                    <TextField
                                        resource="group"
                                        source="public.code"
                                    />
                                </Labeled>
                            </Box>
                            <Box
                                display={{ xs: 'block', sm: 'flex' }}
                                sx={{ marginBottom: '10px' }}
                            >
                                <Labeled
                                    label={translate(
                                        'resources.group.fields.public.group_type'
                                    )}
                                >
                                    <FunctionField
                                        render={record =>
                                            record.public.group_type ==
                                            'comprehensiveschool'
                                                ? 'Perusopetus'
                                                : record.public.group_type ==
                                                  'highschool16'
                                                ? 'LOPS (2016)'
                                                : record.public.group_type ==
                                                  'highschool21'
                                                ? 'LOPS (2021)'
                                                : record.public.group_type ==
                                                  'flschool'
                                                ? 'Finnlectura'
                                                : ''
                                        }
                                    />
                                </Labeled>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <Typography variant="subtitle2" paragraph>
                                Materiaalit
                            </Typography>
                            <ArrayField
                                resource="group"
                                source="public.materials"
                            >
                                <Datagrid
                                    bulkActionButtons={false}
                                    style={{ marginBottom: '30px' }}
                                >
                                    <TextField
                                        source="sarja"
                                        sortable={false}
                                    />
                                    <TextField
                                        source="kirja"
                                        sortable={false}
                                    />
                                    <TextField source="isbn" sortable={false} />
                                    <BooleanField
                                        source="jaettava"
                                        sortable={false}
                                    />
                                </Datagrid>
                            </ArrayField>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <Box
                                display={{ xs: 'block', sm: 'flex' }}
                                sx={{ marginBottom: '10px' }}
                            >
                                <Labeled>
                                    <ReferenceField
                                        reference="user"
                                        source="author.creator"
                                    >
                                        <FunctionField
                                            render={record =>
                                                `${record.public.firstname} ${record.public.lastname}, `
                                            }
                                        />
                                        <DateField
                                            resource="user"
                                            source="author.created"
                                            showTime
                                        />
                                    </ReferenceField>
                                </Labeled>
                            </Box>
                            <Box
                                display={{ xs: 'block', sm: 'flex' }}
                                sx={{ marginBottom: '10px' }}
                            >
                                <Labeled>
                                    <ReferenceField
                                        reference="user"
                                        source="author.modifier"
                                    >
                                        <FunctionField
                                            render={record =>
                                                `${record.public.firstname} ${record.public.lastname}, `
                                            }
                                        />
                                        <DateField
                                            resource="user"
                                            source="author.modified"
                                            showTime
                                        />
                                    </ReferenceField>
                                </Labeled>
                            </Box>
                        </Grid>
                    </Grid>
                </FormTab>

                <FormTab
                    label="resources.school.tabs.teachers"
                    icon={<School />}
                    path="opettajat"
                >
                    <Grid container width={{ xs: '100%' }} spacing={2}>
                        <Grid item xs={12} md={12}>
                            <CustomUserList
                                disableSyncWithLocation
                                storeKey="groupTeacherList"
                                resource="teacher"
                                filter={{
                                    roletype: '0,1',
                                    status: '0,2,3',
                                    groups_status: '0,2,3',
                                }}
                                customFilters={['group']}
                                showExtra={['search']}
                                hideFields={[
                                    'email',
                                    'role',
                                    'subjects',
                                    'code',
                                    'middlename',
                                    'loginstudent',
                                ]}
                                sort={{
                                    field: 'public.lastname',
                                    order: 'ASC',
                                }}
                                bulkActionButtons={false}
                                emptyIcon={user.iconTeacher}
                            >
                                <span />
                            </CustomUserList>
                        </Grid>
                    </Grid>
                </FormTab>

                <FormTab
                    label="resources.school.tabs.pupils"
                    icon={<Face5 />}
                    path="oppilaat"
                >
                    <Grid container width={{ xs: '100%' }} spacing={2}>
                        <Grid item xs={12} md={12}>
                            <CustomUserList
                                disableSyncWithLocation
                                storeKey="groupPupilList"
                                resource="pupil"
                                customFilters={['group']}
                                showExtra={['search']}
                                hideFields={[
                                    'loginteacher',
                                    'subjects',
                                    'role',
                                    'loginstudent',
                                    'email',
                                ]}
                                sort={{
                                    field: 'public.lastname',
                                    order: 'ASC',
                                }}
                                bulkActionButtons={false}
                                emptyIcon={user.iconPupil}
                            >
                                <span />
                            </CustomUserList>
                        </Grid>
                    </Grid>
                </FormTab>

                <FormTab
                    label="resources.school.tabs.students"
                    icon={<Face />}
                    path="opiskelijat"
                >
                    <Grid container width={{ xs: '100%' }} spacing={2}>
                        <Grid item xs={12} md={12}>
                            <CustomUserList
                                disableSyncWithLocation
                                storeKey="groupStudentList"
                                resource="student"
                                customFilters={['group']}
                                showExtra={['search']}
                                hideFields={[
                                    'code',
                                    'middlename',
                                    'subjects',
                                    'role',
                                    'loginteacher',
                                ]}
                                sort={{
                                    field: 'public.lastname',
                                    order: 'ASC',
                                }}
                                bulkActionButtons={false}
                                emptyIcon={user.iconStudent}
                            >
                                <span />
                            </CustomUserList>
                        </Grid>
                    </Grid>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

const redirect: any = () => {
    return window.location.hash.replace('#', '');
};

export default GroupEdit;
