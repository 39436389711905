import * as React from 'react';
import { List, Datagrid, TextField, SearchInput, useTranslate, useEditContext, ListProps } from 'react-admin';
import CustomEmpty from './CustomEmpty';

interface Props extends ListProps {
    bulkActionButtons?: false | React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
    sortable?: boolean;
    showExtra?: any;
    hideFields?: any;
    customFilters?: any;
    refreshKey?: string;
    storeKey?: string;
}

const CustomGroupList = (props: Props) => {
    const {
        sortable = true,
        showExtra = [],
        hideFields = [],
        customFilters = [],
        refreshKey = 'refreshAll',
        storeKey = 'customGroupList',
        // ...other props
    } = props;

    const translate = useTranslate();
    const parent = useEditContext();
    var filter = {};

    if (props.filter)
        filter = props.filter;

    if (props.customFilters?.includes("parent_guid"))
        filter["parent_guid"] = parent.record.guid;

    if (props.customFilters?.includes("user_guid"))
        filter["user_guid"] = parent.record.guid;

    return (
        <List {...props} actions={false} filter={filter} filters={props.showExtra?.includes("search") ? [<SearchInput source="q" size="small" alwaysOn />] : undefined} empty={<CustomEmpty />}>
            <Datagrid bulkActionButtons={props.bulkActionButtons} rowClick="edit" className='cgl-dg'>
                {!props.hideFields?.includes("name") && <TextField source="name" label={translate('resources.group.fields.name')} sortable={props.sortable} />}
            </Datagrid>
        </List>
    );
};

export default CustomGroupList;
