import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ProductList from './ProductList';
import ProductEdit from './ProductEdit';
import ProductCreate from './ProductCreate';

export default {
    list: ProductList,
    create: ProductCreate,
    edit: ProductEdit,
    icon: LibraryBooksIcon,
};

export const productLifecycleCallbacks = {
    resource: 'product',
    beforeUpdate: async (params, dataProvider) => {

        if (params.data.files && params.data.files.length > 0) {
            const newFiles = params.data.files.filter(
                p => p.rawFile instanceof File
            );
            const formerFiles = params.data.files.filter(
                p => !(p.rawFile instanceof File)
            );

            const base64Files = await Promise.all(
                newFiles.map(convertFileToBase64)
            )

            const files = [
                ...base64Files.map((dataUrl, index) => ({
                    src: dataUrl,
                    title: newFiles[index].title,
                })),
                ...formerFiles,
            ];

            params.data.files = files;
        }

        return params;
    },
};

const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file.rawFile);
    });