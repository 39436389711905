import * as React from 'react';
import { NumberInput, NumberInputProps } from 'react-admin';

interface Props extends NumberInputProps {
    validateOnlyIfNotEmpty?: boolean,
}

const CustomNumberInput = (props: Props) => {
    const { validateOnlyIfNotEmpty = false, ...rest } = props;

    return (
        <NumberInput
            {...rest}
        />
    );
};

export default CustomNumberInput;
