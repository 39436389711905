import Diversity1 from '@mui/icons-material/Diversity1';
import GroupList from './GroupList';
import GroupEdit from './GroupEdit';
// import ProductCreate from './ProductCreate';

export default {
    list: GroupList,
    // create: ProductCreate,
    edit: GroupEdit,
    icon: Diversity1,
};