import SchoolIcon from '@mui/icons-material/School';
import TeacherList from './TeacherList';
import TeacherEdit from './TeacherEdit';
// import ProductCreate from './ProductCreate';

export default {
    list: TeacherList,
    // create: ProductCreate,
    edit: TeacherEdit,
    icon: SchoolIcon,
};