import * as React from 'react';
import { FC, createElement } from 'react';
import { Card, Box, Typography, Divider } from '@mui/material';
import { ReactNode } from 'react';
import { StatsTooltip } from '../common';

interface Props {
    icon?: FC<any>;
    to: string;
    title?: string;
    subtitle?: string | number;
    tooltip?: string;
    children?: ReactNode;
}

const StatsWithIcon = (props: Props) => {
    const { icon, title, subtitle, tooltip, children } = props;
    return (
        // @ts-ignore
        <Card
            sx={{
                minHeight: 52,
                display: 'flex',
                flexDirection: 'column',
                flex: '1',
                '& a': {
                    textDecoration: 'none',
                    color: 'inherit',
                },
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    overflow: 'hidden',
                    padding: '16px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    '& .icon': {
                        color: 'primary.main',
                    },
                    '&:before': {
                        position: 'absolute',
                        top: '50%',
                        left: 0,
                        display: 'block',
                        content: `''`,
                        height: '200%',
                        aspectRatio: '1',
                        transform: 'translate(-30%, -60%)',
                        borderRadius: '50%',
                        backgroundColor: 'primary.main',
                        opacity: 0.15,
                    },
                }}
            >
                <Box width="3em" className="icon">
                    {icon && createElement(icon, { fontSize: 'large' })}
                </Box>
                {tooltip ? (
                    <StatsTooltip
                        title={tooltip}
                        placement="top-start"
                        followCursor
                    >
                        <Box textAlign="right">
                            <Typography color="textSecondary">
                                {title}
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {subtitle || ' '}
                            </Typography>
                        </Box>
                    </StatsTooltip>
                ) : (
                    <Box textAlign="right">
                        <Typography color="textSecondary">{title}</Typography>
                        <Typography variant="h5" component="h2">
                            {subtitle || ' '}
                        </Typography>
                    </Box>
                )}
            </Box>

            {children && <Divider />}
            {children}
        </Card>
    );
};

export default StatsWithIcon;
