import * as React from 'react';
import { useRecordContext, useDataProvider } from 'react-admin';
import { useQuery } from '@tanstack/react-query';
import {get,find} from 'lodash';

interface Props {
    source?: string;
    label?: string;
    sortable?: boolean;
}

const SubjectListField = ({
    source = "group",
    label = "",
    sortable = false
}: Props) => {
   
    const dataProvider = useDataProvider();
    const record = useRecordContext();
    
    const url = "extralist/teacher/roles";
    let params = '';

    const { data,error,isPending,refetch } = useQuery({
        queryKey: [url, 'getAny', params], 
        queryFn: () => dataProvider.getAny(url, params)
    });

    if (!data) return null;

    let foundSubroles = data.data[0];
    let teacherSubroles = get((find(record.group,{type:2}) || record.group[0]),'public.subject',[]);
    let subroles = "";

    if(foundSubroles && teacherSubroles.length > 0){
        subroles = teacherSubroles.map(x => {
           let subrole = find(foundSubroles.subject,{guid:x.toUpperCase()});
           return subrole ? subrole.abbreviation : '-';
        }).join(', ');
    }

    return (
        <div>{subroles}</div>
    );
};

export default SubjectListField;
