import * as React from 'react';
import { Identifier, RaRecord, List, Datagrid, TextField, SearchInput, useTranslate, useEditContext,ListProps} from 'react-admin';
import CustomEmpty from './CustomEmpty';
import Diversity1 from '@mui/icons-material/Diversity1';

interface Props extends ListProps {
    bulkActionButtons?: false | React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
    sortable?: boolean;
    showExtra?: any;
    hideFields?: any;
    customFilters?: any;
    refreshKey?: string;
    storeKey?: string;
    params?: any;
}

const CustomUserGroupList = (props: Props) => {

    const translate = useTranslate();
    const cRowClick = (id: Identifier, resource: string, record: RaRecord) => {
        //console.log('cRowClick', id, resource, record);
        return '/group/'+record.group.guid;
    };
    const parent = useEditContext();
    // console.log('parent',parent);
    // const useRecord = useRecordContext();
    // console.log('useRecord',useRecord);
    
    //console.log('expanded',props);
    

    var filter = {};

    if (props.filter)
        filter = props.filter;

    if (props.customFilters?.includes("parent_guid"))
        filter["parent_guid"] = parent.record.group_guid ? parent.record.group_guid : parent.record.guid;

    if (props.customFilters?.includes("user_guid"))
        filter["user_guid"] = parent.record.user_guid ? parent.record.user_guid : props.params["user_guid"];

    return (
        <List sx={{margin: '20px 10px 0px 70px'}} {...props} actions={false} filter={filter} filters={props.showExtra?.includes("search") ? [<SearchInput source="q" size="small" alwaysOn />] : undefined} empty={<CustomEmpty icon={Diversity1} />}>
            {/* <Datagrid bulkActionButtons={props.bulkActionButtons} expand={<GroupEdit />} expandSingle={true} rowClick="expand" className='cgl-dg'>
                {!props.hideFields?.includes("name") && <TextField source="group.name" label={translate('resources.group.fields.name')} sortable={props.sortable} />}
            </Datagrid> */}
            <Datagrid bulkActionButtons={props.bulkActionButtons} rowClick={cRowClick} className='cgl-dg'>
                {!props.hideFields?.includes("name") && <TextField source="group.name" label={translate('resources.group.name', 2)} sortable={props.sortable} />}
            </Datagrid>
        </List>
    );
};

export default CustomUserGroupList;
