import { FC, createElement } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import {
    Stack,
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Alert,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import { useTranslate } from 'react-admin';
import { numberFormatter, appIcons } from './common';
import { Filter, Handler, ActiveSession } from './types';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

const StyledIcon: FC<any> = ({ icon }) => {
    return (
        <StyledBadge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant="dot"
        >
            <Avatar
                component={icon}
                sx={{
                    width: 24,
                    height: 24,
                    color: 'primary.main',
                    backgroundColor: 'white',
                }}
            />
        </StyledBadge>
    );
};

const ActiveSessionsByRoles = (data: ActiveSession[]) => {
    const translate = useTranslate();
    return (
        <List>
            {data.map(item => {
                const ItemText =
                    item.app === 'opiskelija'
                        ? translate('statistics.fields.students')
                        : item.app === 'opepalvelu'
                        ? translate('statistics.fields.teachers')
                        : translate('statistics.fields.pupils');
                const Icon =
                    item.app === 'opiskelija'
                        ? appIcons.student
                        : item.app === 'opepalvelu'
                        ? appIcons.teacher
                        : appIcons.pupil;
                return (
                    <ListItem key={item.guid}>
                        <ListItemAvatar>
                            <StyledIcon icon={Icon} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={`${ItemText} ${numberFormatter.format(
                                item.total
                            )}`}
                        />
                    </ListItem>
                );
            })}
        </List>
    );
};

const ActiveSessionsByRolesGraph = (data: ActiveSession[]) => {
    const translate = useTranslate();
    if (!data[0]) {
        return (
            <Alert severity="warning" sx={{ padding: 10 }}>
                {translate('statistics.nodata')}
            </Alert>
        );
    }

    const xSeries = {
        data: data.map(item => item.total),
    };

    const xAxis = data.map(item => item.app);
    console.log(xSeries);

    return (
        <BarChart
            height={400}
            series={[xSeries]}
            xAxis={[{ data: xAxis, scaleType: 'band' }]}
        />
    );
};

const ActiveSessions = (
    props: { data: ActiveSession[] | null } & Handler & Filter
) => {
    const translate = useTranslate();
    const { data, handler, mode } = props;
    const totalSessions = numberFormatter.format(
        data.reduce((sum, item) => sum + item.total, 0)
    );

    return (
        <>
            {createElement(handler, {
                title: translate('statistics.activesessions.title'),
                subtitle: String(totalSessions),
                tooltip: translate('statistics.activesessions.tooltip'),
                icon: appIcons.all,
                children: (() => {
                    switch (mode) {
                        case 'List':
                            return ActiveSessionsByRoles(data);
                        case 'Graph':
                            return ActiveSessionsByRolesGraph(data);
                        default:
                            return null;
                    }
                })(),
            })}
        </>
    );
};
export default ActiveSessions;
