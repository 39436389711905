import React, { FC, createElement } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';

import {
    Stack,
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Alert,
} from '@mui/material';

import { useTranslate } from 'react-admin';
import {
    numberFormatter,
    dateFormatter,
    resolveDate,
    daysBetweenDates,
    appIcons,
} from './common';
import { Filter, Handler, ProdcutTransition } from './types';
import Chip from '@mui/material/Chip';
import { Link } from 'react-router-dom';

const ProductTransitionsTable: FC<{ data: ProdcutTransition[] }> = ({
    data,
}) => {
    const totalTransitions = data.reduce((sum, item) => sum + item.count, 0);
    const translate = useTranslate();
    if (totalTransitions === 0) {
        return (
            <Alert
                severity="warning"
                sx={{
                    padding: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {translate('statistics.nodata')}
            </Alert>
        );
    }
    return (
        <List>
            {data.map(item => (
                <Link
                    to={`/product/${item.guid}/kaytto_historia`}
                    style={{ textDecoration: 'none' }}
                    key={item.guid}
                >
                    <ListItem
                        dense
                        sx={{
                            '&:hover': {
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                transform: 'scale(1.01)',
                                transition: 'transform 0.2s, box-shadow 0.2s',
                            },
                        }}
                    >
                        <ListItemAvatar>
                            <Avatar
                                alt=""
                                sx={{ borderRadius: 0 }}
                                src={`https://mediapankki.otava.fi/api/v1/assets/by-isbn/${item.isbn}.jpg?maxWidth=60&maxHeight=60`} // Dynamic src based on item.isbn
                            />
                        </ListItemAvatar>
                        <ListItemText
                            primary={item.name}
                            secondary={
                                <>
                                    <Chip
                                        label={numberFormatter.format(
                                            item.count
                                        )}
                                    />
                                    {` | ${Math.round(
                                        (item.count / totalTransitions) * 100
                                    )}% | ${item.isbn} | ${
                                        item.materialstorage_name
                                    }`}
                                </>
                            }
                        />
                    </ListItem>
                </Link>
            ))}
        </List>
    );
};

const ProductTransitionsGraph: FC<{
    data: ProdcutTransition[];
}> = ({ data }) => {
    const translate = useTranslate();
    if (!data[0]?.group_by) {
        return (
            <Alert
                severity="warning"
                sx={{
                    padding: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {translate('statistics.nodata')}
            </Alert>
        );
    }
    const xSeries = data.map(item => {
        return {
            data: item.group_by.map(i => i.count),
            label: item.name,
            id: item.guid,
        };
    });

    let xAxis = [];

    xAxis = data[0].group_by.map(item =>
        dateFormatter.format(new Date(item.date))
    );

    return (
        <BarChart
            height={500}
            series={xSeries}
            xAxis={[{ data: xAxis, scaleType: 'band' }]}
        />
    );
};

const ProductTransitions = (
    props: { data: ProdcutTransition[] | null } & Handler & Filter
) => {
    const translate = useTranslate();
    const { data, handler, mode, only_data, from, to, limit } = props;
    const totalTransitions = numberFormatter.format(
        data.reduce((sum, item) => sum + item.count, 0)
    );
    const dateFrom = resolveDate(from);
    const dateTo = resolveDate(to);
    const days = daysBetweenDates(dateFrom, dateTo);

    const title = translate('statistics.producttransitions.title');
    const tooltip =
        days && limit
            ? translate('statistics.producttransitions.tooltip', {
                  day: days,
                  limit: limit,
              })
            : days
            ? translate('statistics.producttransitions.tooltip_single', {
                  day: days,
              })
            : limit
            ? translate('statistics.producttransitions.tooltip_limit', {
                  limit: limit,
              })
            : title;

    return (
        <>
            {mode === 'Single' &&
                createElement(handler, {
                    key: 'product_transitions',
                    title: days === 1 ? title : tooltip,
                    subtitle: String(totalTransitions),
                    tooltip: days === 1 ? tooltip : null,
                    icon: appIcons.product,
                })}
            {mode === 'List' &&
                only_data &&
                createElement(ProductTransitionsTable, { data })}
            {mode === 'Graph' &&
                only_data &&
                createElement(ProductTransitionsGraph, { data })}
            {mode === 'Graph' &&
                !only_data &&
                createElement(handler, {
                    title: days === 1 ? title : tooltip,
                    subtitle: String(totalTransitions),
                    tooltip: days === 1 ? tooltip : null,
                    icon: appIcons.product,
                    children: createElement(ProductTransitionsGraph, {
                        data,
                    }),
                })}
            {mode === 'List' &&
                !only_data &&
                createElement(handler, {
                    title: days === 1 ? title : tooltip,
                    subtitle: String(totalTransitions),
                    tooltip: days === 1 ? tooltip : null,
                    icon: appIcons.product,
                    children: createElement(ProductTransitionsTable, {
                        data,
                    }),
                })}
        </>
    );
};
export default ProductTransitions;
