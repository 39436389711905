import * as React from 'react';
import {
    Breadcrumb,
    BreadcrumbItem,
    ResourceBreadcrumbItems,
} from '@react-admin/ra-navigation';
import { useCreatePath, useTranslate, RaRecord } from 'react-admin';
import {get} from 'lodash';
import clsx from 'clsx';

const CustomBreadcrumb = () => {
    const translate = useTranslate();
    const createPath = useCreatePath();

    const editLabel = translate('ra.action.edit');
    const createLabel = translate('ra.action.create');
    return (
        <Breadcrumb
            sx={{
                fontSize: 'small',
                // Display the Breadcrumb over the custom Layout of some pages by adding a zIndex and a maxWidth
                // @see "src/products/ProductList.tsx" or "src/visitors/VisitorList.tsx"
                maxWidth: '700px',
                zIndex: 1,
                '& a': {
                    pointerEvents: 'visible',
                },
                marginBottom: -3,
            }}
        >
            {/* <ResourceBreadcrumbItems
                resources={['product','subscription','school','announcement','application']}
            /> */}

            <BreadcrumbItem
                name="user"
                label={translate('resources.user.name', 2)}
                className={clsx(`muru`)}
            >
                <BreadcrumbItem
                    name="edit"
                    label={({ record }: { record?: RaRecord }): string =>
                        `${
                            record ? get(record, 'public.firstname') + ' ' + get(record, 'public.lastname') : '...'
                        }`
                    }
                    className={clsx(`muru`)}
                />
            </BreadcrumbItem>

            <BreadcrumbItem
                name="teacher"
                label={translate('resources.teacher.name', 2)}
                className={clsx(`muru`)}
            >
                <BreadcrumbItem
                    name="edit"
                    label={({ record }: { record?: RaRecord }): string =>
                        `${
                            record ? get(record, 'public.firstname') + ' ' + get(record, 'public.lastname') : '...'
                        }`
                    }
                    className={clsx(`muru`)}
                />
            </BreadcrumbItem>

            <BreadcrumbItem
                name="student"
                label={translate('resources.student.name', 2)}
                className={clsx(`muru`)}
            >
                <BreadcrumbItem
                    name="edit"
                    label={({ record }: { record?: RaRecord }): string =>
                        `${
                            record ? get(record, 'public.firstname') + ' ' + get(record, 'public.lastname') : '...'
                        }`
                    }
                    className={clsx(`muru`)}
                />
            </BreadcrumbItem>

            <BreadcrumbItem
                name="pupil"
                label={translate('resources.pupil.name', 2)}
                className={clsx(`muru`)}
            >
                <BreadcrumbItem
                    name="edit"
                    label={({ record }: { record?: RaRecord }): string =>
                        `${
                            record ? get(record, 'public.firstname') + ' ' + get(record, 'public.lastname') : '...'
                        }`
                    }
                    className={clsx(`muru`)}
                />
            </BreadcrumbItem>

            <BreadcrumbItem
                name="product"
                label={translate('resources.product.name', 2)}
                className={clsx(`muru`)}
            >
                <BreadcrumbItem
                    name="edit"
                    label={({ record }: { record?: RaRecord }): string =>
                        `${
                            record ? get(record, 'name') : '...'
                        }`
                    }
                    className={clsx(`muru`)}
                />
            </BreadcrumbItem>

            <BreadcrumbItem
                name="subscription"
                label={translate('resources.subscription.name', 2)}
                className={clsx(`muru`)}
            >
            </BreadcrumbItem>

            <BreadcrumbItem
                name="school"
                label={translate('resources.school.name', 2)}
                className={clsx(`muru`)}
            >
                <BreadcrumbItem
                    name="edit"
                    label={({ record }: { record?: RaRecord }): string =>
                        `${
                            record ? get(record, 'name') : '...'
                        }`
                    }
                    className={clsx(`muru`)}
                />
            </BreadcrumbItem>

            <BreadcrumbItem
                name="announcement"
                label={translate('resources.announcement.name', 2)}
                className={clsx(`muru`)}
            >
                <BreadcrumbItem
                    name="edit"
                    label={({ record }: { record?: RaRecord }): string =>
                        `${
                            record ? get(record, 'name') : '...'
                        }`
                    }
                    className={clsx(`muru`)}
                />
            </BreadcrumbItem>

            <BreadcrumbItem
                name="application"
                label={translate('resources.application.name', 2)}
                className={clsx(`muru`)}
            >
                <BreadcrumbItem
                    name="edit"
                    label={({ record }: { record?: RaRecord }): string =>
                        `${
                            record ? get(record, 'name') : '...'
                        }`
                    }
                    className={clsx(`muru`)}
                />
            </BreadcrumbItem>

        </Breadcrumb>

    );
};

export default CustomBreadcrumb;
