import * as React from 'react';
import { FC } from 'react';
import { List, Datagrid, TextField, useTranslate, useEditContext,  ListProps } from 'react-admin';
import CustomEmpty from './CustomEmpty';
import CustomSelectInput from './CustomSelectInput';
import CustomUserLicenseField from './CustomUserLicenseField';

interface Props extends ListProps {
    bulkActionButtons?: false | React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
    sortable?: boolean;
    showExtra?: any;
    hideFields?: any;
    customFilters?: any;
    refreshKey?: string;
}

const CustomUserLicenseList: FC<Props> = ({
    bulkActionButtons = false,
    sortable = true,
    showExtra = [],
    hideFields = [],
    customFilters = [],
    refreshKey = 'refreshAll',
    ...props
}) => {
    const translate = useTranslate();
    const parent = useEditContext();
    const filter = customFilters.includes("subscription_guid") ? {"subscription_guid": parent.record.guid} : undefined;

    return (
        <List {...props} actions={false} filter={filter} filters={showExtra.includes("search") ? [<CustomSelectInput url="extralist/subscription/groups" source="group_guid" label={translate('resources.userlicense.fields.groups')} filter="guid" alwaysOn />] : undefined} empty={<CustomEmpty />}>
            <Datagrid bulkActionButtons={bulkActionButtons}>
                {!hideFields.includes("lastname") && <TextField source="lastname" label={translate('resources.userlicense.fields.lastname')} sortable={sortable} />}
                {!hideFields.includes("firstname") && <TextField source="firstname" label={translate('resources.userlicense.fields.firstname')} sortable={sortable} />}
                {!hideFields.includes("email") && <TextField source="email" label={translate('resources.userlicense.fields.email')} sortable={sortable} />}
                {!hideFields.includes("licenses") && <CustomUserLicenseField source="licenses" label={translate('resources.userlicense.fields.licenses')} />}
            </Datagrid>
        </List>
    );
};

export default CustomUserLicenseList;
