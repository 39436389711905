import React, { useState, useEffect } from 'react';
import {
    Grid,
    Button,
    ButtonGroup,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Stack,
} from '@mui/material';
import CustomStatistics from '../resources/controls/CustomStatistics';
import StatsWithIcon from './statistics/controls/StatsWithIcon';
import { IfCanAccess, canAccess } from '@react-admin/ra-rbac';
import { Modes } from './statistics/types';
import { defaultPeriods } from './statistics/common';
import { useTranslate } from 'react-admin';

const StatisticsTile = ({
    permissions,
    showSessions = false,
}: {
    permissions: any;
    showSessions?: boolean;
}) => {
    const translate = useTranslate();
    const [mode, setMode] = useState<Modes>('List');
    const [from, setFrom] = useState<string>(
        defaultPeriods.find(p => p.default)?.value || '1d'
    );
    const [group_by, setGroupBy] = useState<string>(
        defaultPeriods.find(p => p.default)?.group_by || 'day'
    );

    useEffect(() => {
        console.log('StatisticsTile state updated:', { mode, from, group_by });
    }, [mode, from, group_by]);

    return (
        <IfCanAccess action="show" resource="user">
            <Grid item xs={4}>
                <Stack direction="column" spacing={2}>
                    <Stack direction="row" spacing={2}>
                        <ButtonGroup
                            variant="contained"
                            aria-label="outlined primary button group"
                        >
                            <Button
                                onClick={() => setMode('List')}
                                variant={
                                    mode === 'List' ? 'contained' : 'outlined'
                                }
                            >
                                {translate('statistics.modes.list')}
                            </Button>
                            <Button
                                onClick={() => setMode('Graph')}
                                variant={
                                    mode === 'Graph' ? 'contained' : 'outlined'
                                }
                            >
                                {translate('statistics.modes.graph')}
                            </Button>
                        </ButtonGroup>

                        <FormControl
                            variant="outlined"
                            style={{ marginLeft: '1em' }}
                        >
                            <InputLabel id="from-label">
                                {translate('statistics.fields.from')}
                            </InputLabel>
                            <Select
                                labelId="from-label"
                                value={from}
                                onChange={e => {
                                    setFrom(e.target.value as string);
                                    setGroupBy(
                                        defaultPeriods.find(
                                            p => p.value === e.target.value
                                        )?.group_by || 'day'
                                    );
                                }}
                                label="From"
                            >
                                {defaultPeriods.map(p => (
                                    <MenuItem key={p.value} value={p.value}>
                                        {translate(p.label)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>
                    {showSessions && (
                        <CustomStatistics
                            statisticKey="activeSessions"
                            handler={StatsWithIcon}
                            mode={mode}
                            from={from}
                        />
                    )}
                    <CustomStatistics
                        statisticKey="activeUsers"
                        handler={StatsWithIcon}
                        mode={mode}
                        from={from}
                        ttl={60}
                    />
                    <CustomStatistics
                        statisticKey="newUsers"
                        handler={StatsWithIcon}
                        mode={mode}
                        from={from}
                        ttl={60}
                    />
                </Stack>
            </Grid>
            <Grid item xs={8} paddingLeft={2}>
                <CustomStatistics
                    statisticKey="productTransitions"
                    handler={StatsWithIcon}
                    mode="List"
                    from={from}
                    group_by={group_by}
                    limit={15}
                    ttl={60}
                />
            </Grid>
        </IfCanAccess>
    );
};

export default StatisticsTile;
