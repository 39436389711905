import PeopleIcon from '@mui/icons-material/People';
import GroupIcon from '@mui/icons-material/Group';
import SchoolIcon from '@mui/icons-material/School';
import FaceIcon from '@mui/icons-material/Face';
import Face5Icon from '@mui/icons-material/Face5';
import UserList from './UserList';
import UserEdit from './UserEdit';
// import ProductCreate from './ProductCreate';

export default {
    list: UserList,
    // create: ProductCreate,
    edit: UserEdit,
    icon: PeopleIcon,
    iconAll: GroupIcon,
    iconTeacher: SchoolIcon,
    iconStudent: FaceIcon,
    iconPupil: Face5Icon,
};