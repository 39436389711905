import FaceIcon from '@mui/icons-material/Face';
import StudentList from './StudentList';
import StudentEdit from './StudentEdit';
// import ProductCreate from './ProductCreate';

export default {
    list: StudentList,
    // create: ProductCreate,
    edit: StudentEdit,
    icon: FaceIcon,
};