import * as React from 'react';
import { useState,useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import {
    useTranslate,
    useLogin,
    useNotify,
} from 'react-admin';

import {Button} from '@mui/material';
import Box from '@mui/material/Box';

const OtavaAdLogin = () => {

    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState('');
    const translate = useTranslate();
    const notify = useNotify();
    const login = useLogin();
    const location = useLocation();    
    const prosessing = location.pathname.startsWith('/oidc-login')
    
    useEffect(()=>{  

        if(prosessing){
            setLoading(true);
            //setStatus('_success');
            login({
                    code:location.pathname.split('/')[2]
                  },
                '/'
                ).catch((error: Error) => {
                    setLoading(false);
                    notify(
                        typeof error === 'string'
                            ? error
                            : typeof error === 'undefined' || !error.message
                            ? 'ra.auth.sign_in_error'
                            : error.message,
                        {
                            type: 'warning',
                            messageArgs: {
                                _:
                                    typeof error === 'string'
                                        ? error
                                        : error && error.message
                                        ? error.message
                                        : undefined,
                            },
                        }
                    );
                });
        }

    }, [])  

    return (    

        <Box
        sx={{
            margin: '1em',
            display: 'flex',
            justifyContent: 'center',
        }} >
        {prosessing ? 

            <Button
            variant="outlined"
            color="primary"
            disabled={loading}
            fullWidth
            onClick={()=>{

                let uri = window.location.origin + '/#/login';
                window.location.replace(uri);
            }}
            >
            {translate(`login.otava_ad${status}`)}
            </Button>

        :         
            <Button
                variant="outlined"
                color="primary"
                disabled={loading}
                fullWidth
                onClick={()=>{

                    let params = new URLSearchParams(location.search);

                    let debug = params.get('debug') === 'true';
                    let prompt = params.get('prompt');
                    let lang = params.get('lang');
        
                    let port = window.location.hostname === 'localhost' ? ':8099' : '';
                    let path = window.location.hostname === 'localhost' ? '/cb/azuread' : '/api/cb/azuread';
                  
                    let code_verifier = '';//Math.random().toString(36).substr(2);
                    let code_challenge = '';//sha256(code_verifier);
                    let state = Math.random().toString(36).substr(2);
                    let nonce = Math.random().toString(36).substr(2);
                    let client_id = '3dd06b6a-56fd-4df0-aa17-9b630c41c4a3';
                    
                    //localStorage.setItem('code_verifier',code_verifier);
                    //localStorage.setItem('code_challenge',code_challenge);
                    localStorage.setItem('state',state);
                    localStorage.setItem('nonce',nonce);
        
                    let uri = `https://login.microsoftonline.com/77ef7ed0-a39b-4e43-bead-4d716622721a/oauth2/v2.0/authorize
                    ?scope=openid profile email
                    &response_type=code
                    &debug=` + debug + `
                    &lang=`+ (lang ? lang : 'fi') +`
                    &client_id=` + client_id + `
                    &nonce=` + encodeURIComponent(nonce) + `
                    &state=` + encodeURIComponent(state) + `
                    &code_challenge=` + encodeURIComponent(code_challenge) + `
                    &redirect_uri=` + encodeURI(window.location.protocol + '//' + window.location.hostname + port + path);
                    
                    uri = uri.replace(/\n\s*/g,'');
                    console.log(uri);
        
                    
                    if(debug)
                        console.log(uri);
                    else
                         window.location.replace(uri);
                }}
            >
                {translate('login.otava_ad')}
            </Button>}
        </Box>        

    );
};

export default OtavaAdLogin;