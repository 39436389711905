import React from 'react';
import { Grid, useMediaQuery, Theme } from '@mui/material';
import Welcome from './Welcome';
import { usePermissions } from 'react-admin';
import StatisticsTile from '../components/StatisticsTile';

const Dashboard = () => {
    const { isPending, permissions } = usePermissions();
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );
    const isSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('lg')
    );
    if (isPending) return null;
    return isXSmall ? (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Welcome />
            </Grid>
            <Grid item xs={12} marginTop={4}>
                <StatisticsTile permissions={permissions} />
            </Grid>
        </Grid>
    ) : isSmall ? (
        <Grid container padding={0}>
            <Grid item xs={12}>
                <Welcome />
            </Grid>
            <Grid item xs={12} marginTop={4}>
                <StatisticsTile permissions={permissions} />
            </Grid>
        </Grid>
    ) : (
        <Grid container padding={4}>
            <Grid item xs={12}>
                <Welcome />
            </Grid>
            <Grid container marginTop={4}>
                <StatisticsTile permissions={permissions} showSessions={true} />
            </Grid>
        </Grid>
    );
};

export default Dashboard;
