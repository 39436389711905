import * as React from 'react';
import { List, Datagrid, TextField, useTranslate, useEditContext,ListProps, DateField, FunctionField } from 'react-admin';
import CustomEmpty from './CustomEmpty';
import SubscriberFullNameField from '../subscription/SubscriberFullNameField';

interface Props extends ListProps {
    bulkActionButtons?: false | React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
    sortable?: boolean;
    showExtra?: any;
    hideFields?: any;
    customFilters?: any;
    refreshKey?: string;
}

const CustomUserLicenseByProductList = ({
    bulkActionButtons,
    sortable = true,
    showExtra = [],
    hideFields = [],
    customFilters = [],
    refreshKey = 'refreshAll',
    ...props
}: Props) => {

    const translate = useTranslate();

    const parent = useEditContext();
    const filter = customFilters?.includes("product_guid") ? {"product_guid": parent.record.guid} :  undefined;
    //console.log(filter);
    return (
        <List {...props} actions={false} filter={filter} empty={<CustomEmpty />}>
            <Datagrid bulkActionButtons={bulkActionButtons}>
                {!hideFields?.includes("subscriber") && <SubscriberFullNameField source="subscriber" key="subscriber.name" label={translate('resources.product.fields.subscriber.name')} createLink={true} useIcon={true} sortable={sortable} />}
                {!hideFields?.includes("firstname") && <TextField source="user.firstname" key="user.firstname" label={translate('resources.product.fields.user.firstname')} sortable={sortable} />}
                {!hideFields?.includes("lastname") && <TextField source="user.lastname" key="user.lastname" label={translate('resources.product.fields.user.lastname')} sortable={sortable} />}
                {!hideFields?.includes("email") && <TextField source="user.email" key="user.email" label={translate('resources.product.fields.user.email')} sortable={sortable} />}
                {!hideFields?.includes("subscription") && <FunctionField source="subscription" render={record => `${record.subscription.used_license_count} / ${record.subscription.license_count}`} key="subscription.name" label={translate('resources.product.fields.subscription.name')} sortable={false} />}
                {!hideFields?.includes("valid_from") && <DateField source="license.valid_from" key="license.valid_from" label={translate('resources.product.fields.license.valid_from')} sortable={sortable} />}
                {!hideFields?.includes("valid_until") && <DateField source="license.valid_until" key="license.valid_until" label={translate('resources.product.fields.license.valid_until')} sortable={sortable} />}
            </Datagrid>
        </List>
    );
};

export default CustomUserLicenseByProductList;
