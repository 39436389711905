import * as React from 'react';
import {
    Edit,
    NumberInput,
    TextInput,
    TabbedForm,
    FormTab,
    SaveButton,
    Toolbar,
    DateInput,
} from 'react-admin';
import {
    required,
    minValue,
    maxValue,
    minLength,
    maxLength,
    number,
    email,
    regex,
    choices,
    useRefresh,
} from 'react-admin';
import { Grid, Box } from '@mui/material';


import {
    CustomRadioButtonGroupInput,
    CustomBulkDeleteButton,
    CustomUserLicenseList,
    CustomLogEventList,
} from '../controls';

import ProductFullNameField from '../product/ProductFullNameField';
import SubscriberFullNameField from './SubscriberFullNameField';

import { 
    LibraryBooks,
    Article,
    Podcasts,
    Attachment,
    DataObject,
    History,
    Person,
    ReceiptLong,
    TransferWithinAStation,
    Diversity1,
 } from '@mui/icons-material';

const EditToolbar = () => {
    return (
        <Toolbar>
            <SaveButton />
        </Toolbar>
    );
};

const SubscriptionEdit = () => {

    //const refresh = useRefresh();

    return (
        <Edit redirect={false} mutationMode="optimistic">
            <TabbedForm toolbar={<EditToolbar />} >
                {/* Tähän perustiedot */}
                <FormTab label="resources.subscription.tabs.details" icon={<Article />}>
                    
                        <Grid container width={{ xs: '100%' }} spacing={2}>
                            <Grid item xs={12} md={12}>
                                <ProductFullNameField source="product" key="product.name" createLink={true} useIcon={false} useLabel={true} />
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <SubscriberFullNameField source="subscriber" key="subscriber.name" createLink={true} useIcon={false} useLabel={true} />
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <Box display={{ xs: 'block', sm: 'flex' }}>
                                    <NumberInput resource="subscription" source="license_count" />
                                </Box>
                            </Grid>

                            <Grid item xs={2} md={2}>
                                <Box display={{ xs: 'block', sm: 'flex' }}>
                                    <Box flex={1} mr={{ xs: '0.5em' }}><DateInput resource="subscription" source="valid_from" /></Box>
                                    <Box flex={1} mr={{ xs: '0.5em' }}><DateInput resource="subscription" source="valid_until" /></Box>
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <Box display={{ xs: 'block', sm: 'flex' }}>
                                    <CustomRadioButtonGroupInput resource="subscription" source="type" url="extralist/subscription/types" parse={v => parseInt(v)} />
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <Box display={{ xs: 'block', sm: 'flex' }}>
                                    <CustomRadioButtonGroupInput resource="subscription" source="status" url="extralist/subscription/statuses" parse={v => parseInt(v)} />
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <Box display={{ xs: 'block', sm: 'flex' }}>
                                    <TextInput source="message" label="resources.subscription.fields.message" multiline validate={[required()]} fullWidth />
                                </Box>
                            </Grid>
                        </Grid>
                    
                </FormTab>
                {/* 
                    - Tähän KV tilaukseen liittyvät tiedot. 
                    - Tietoja pitäisi yhdenmukaistaa, koska henkilökohtaiset- ja koulutilaukset ovat erilaisia.
                    - Saisikohan tähän kv:n tilausrivit jotenkin järkevästi
                */}
                {<FormTab label="resources.subscription.tabs.kv" icon={<ReceiptLong />} path="kv">

                    <Grid container width={{ xs: '100%' }} spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <TextInput  resource="subscription" source="public.kausi" readOnly />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <TextInput  resource="subscription" source="public.kvtilausmaara" readOnly />
                            </Box>
                        </Grid>
                            {/* <TextInput  resource="subscription" source="public.kvean" /> */}
                        <Grid item xs={12} md={12}>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <TextInput  resource="subscription" source="public.kvnro" readOnly />
                            </Box>
                            {/* <TextInput  resource="subscription" source="public.kvsource" /> */}
                            {/* <TextInput  resource="subscription" source="public.kvetumaara" />
                            <TextInput  resource="subscription" source="public.kvkoulukoodi" /> */}
                        </Grid>
                    </Grid>
                    
                </FormTab>}
                {/* 
                    - Tähän muokkaushistoria, aluksi samalla tavalla, kuin vanhassa.
                    - Jatkossa nämä voisi tuoda Elasticista. Esim. nappia painamalla hakisi tietyllä tavalla tagitetyt tiedot???
                    - Tähän voisi hakea koko historian kerralla
                */}
                <FormTab label="resources.subscription.tabs.modify_history" icon={<History />} path="muokkaus_historia">

                    <Grid container width={{ xs: '100%' }} spacing={2}>
                        <Grid item xs={12} md={12}>
                            <CustomLogEventList resource='logevent' disableSyncWithLocation customFilters={['resource_guid']} uselog="s" showExtra={[]} hideFields={[]} sortable={false} ><span /></CustomLogEventList>
                        </Grid>
                    </Grid>

                </FormTab>
                {/* 
                    - Tähän käyttöhistoria.
                    - Jatkossa nämä voisi tuoda Elasticista. Esim. nappia painamalla hakisi tietyllä tavalla tagitetyt tiedot???
                    - esim. siirtyminen tilauksen tuotteeseen
                    - Tähän voisi hakea ekalla napin painalluksella esim. viimeisen kuukauden tiedot ja sitten vaikka seuraavilla painalluksilla lisää???
                */}
                {/* <FormTab label="resources.subscription.tabs.usage_history" icon={<TransferWithinAStation />} path="kaytto_historia">
                    
                </FormTab> */}
                {/* 
                    - Tähän ryhmät jossai käytössä/jaossa.
                    - Mitä tietoja tarvitaan?
                */}
                {/* <FormTab label="resources.subscription.tabs.groups" icon={<Diversity1 />} path="ryhmat">
                    
                        
                    
                </FormTab> */}
                <FormTab label="resources.subscription.tabs.license_details" icon={<Person />} path="kaytossa">
                    
                        <Grid container width={{ xs: '100%' }} spacing={2}>
                            <Grid item xs={12} md={12}>
                                <CustomUserLicenseList resource='userlicense' disableSyncWithLocation customFilters={['subscription_guid']} showExtra={['search']} hideFields={[]} sort={{ field: 'lastname', order: 'ASC' }} bulkActionButtons={<CustomBulkDeleteButton />}><span /></CustomUserLicenseList>
                            </Grid>
                        </Grid>
                    
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

const redirect: any = () => {
    return window.location.hash.replace('#', '');
}

export default SubscriptionEdit;