import * as React from 'react';
import { FC, useState, useEffect } from 'react';



//import { useFormState } from 'react-final-form';
import { useWatch } from 'react-hook-form';
import { useDataProvider, Loading, RadioButtonGroupInput } from 'react-admin';
import { useQuery } from '@tanstack/react-query';

import lodash from 'lodash';

interface Props {
    source: string;
    resource?: string;
    optionValue?: string;
    optionText?: string;
    allowEmpty?: boolean;
    url: string;
    qparamkey?: string;
    qparamval?: string;
    fullWidth?: boolean;
    row?: boolean;
    parse?: any;
}

interface ResItem {
    item_id: any;
    item_title: any;
}

const CustomRadioButtonGroupInput: FC<Props> = ({
    source,
    resource = '',
    optionValue = 'item_id',
    optionText = 'item_title',
    allowEmpty = true,
    url,
    qparamkey,
    qparamval = '',
    fullWidth = false,
    row = false,
    ...props
}) => {
    /* const { values } = useFormState();
    const params =
        lodash.get(values, qparamval) != undefined
            ? qparamkey + '=' + lodash.get(values, qparamval)
            : ''; */

    const val = useWatch({ name: qparamval });
    const params = val != undefined
                    ? qparamkey + '=' + val
                    : '';
    const dataProvider = useDataProvider();
    // const [loading, setLoading] = useState(true);
    // const [customChoices, setCustomChoices] = useState<ResItem[]>();

    // useEffect(() => {
    //     dataProvider
    //         .getAny(url, params)
    //         .then(({ data }) => {
    //             setCustomChoices(data);
    //             setLoading(false);
    //         })
    //         .catch(error => {
    //             console.log(error);
    //             setLoading(false);
    //         });
    // }, [params]);

    // if (loading) return <Loading />;

    const { data,error,isPending,refetch } = useQuery({
        queryKey: [url, 'getAny', params], 
        queryFn: () => dataProvider.getAny(url, params)
    });

    if (!data) return null;

    const customChoices = data.data;
    //console.log(customChoices);
    

    if (!customChoices) return null;

    return (
        <RadioButtonGroupInput
            source={source}
            resource={resource}
            optionValue={optionValue}
            optionText={optionText}
            choices={customChoices}
            //allowEmpty={allowEmpty}
            //translateChoice={false}
            fullWidth={fullWidth}
            row={row}
            {...props}
        />
    );
};

export default CustomRadioButtonGroupInput;
