import * as React from 'react';
import { useNotify, useRecordContext } from 'react-admin';
import Button from '@mui/material/Button';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const CustomPreviewMaterialButton: React.FC = () => {
    const record = useRecordContext();
    const notify = useNotify();

    const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();

        let api = '';

        try {
            api = `${process.env.REACT_APP_INFRA_API}/redirect`;
            console.log(api);
        } catch (error) {
            notify(`notifications.WRONG_PARAMETERS`, { type: 'warning' });
            return;
        }

        const token = localStorage.getItem('token');
        if (!token) {
            notify(`notifications.NO_TOKEN`, { type: 'warning' });
            return;
        }

        try {
            const response = await fetch(api, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    material: record.id,
                    onlyurl: true,
                    preview: 1,
                }),
            });

            if (!response.ok) {
                throw new Error('Bad response from server');
            }

            const data = await response.json();
            console.log(JSON.stringify(data.redisData));

            const url = data.url;
            window.open(url, '_blank');
        } catch (error) {
            console.error(error);
            notify(`notifications.MATERIAL_PREVIEW_ERROR`, { type: 'warning' });
        }
    };

    return (
        <Button onClick={handleClick}>
            <RemoveRedEyeIcon />
        </Button>
    );
};

export default CustomPreviewMaterialButton;
