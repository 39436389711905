import DomainIcon from '@mui/icons-material/Domain';
import SchoolList from './SchoolList';
import SchoolEdit from './SchoolEdit';
// import ProductCreate from './ProductCreate';

export default {
    list: SchoolList,
    // create: ProductCreate,
    edit: SchoolEdit,
    icon: DomainIcon,
};