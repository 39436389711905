import React, { FC, createElement } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Tooltip,
    Box,
} from '@mui/material';

import {
    Stack,
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Alert,
} from '@mui/material';

import { useTranslate } from 'react-admin';
import {
    numberFormatter,
    dateFormatter,
    dateTimeFormatter,
    resolveDays,
    appIcons,
    isUuid,
} from './common';
import { Filter, Handler, UserEvent } from './types';
import Chip from '@mui/material/Chip';
import { Link } from 'react-router-dom';
import Icon from '@mui/material/Icon';
import WarningIcon from '@mui/icons-material/WarningTwoTone';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/InfoTwoTone';
import Bowser from 'bowser';

const UserEventsTable: FC<{ data: UserEvent[] }> = ({ data }) => {
    const translate = useTranslate();
    const resolveLevel = (level: string) => {
        switch (level) {
            case 'info':
                return <InfoIcon color="success" />;
            case 'error':
                return <ErrorIcon color="error" />;
            case 'warn':
                return <WarningIcon color="warning" />;
            default:
                return <InfoIcon color="primary" />;
        }
    };
    const resolveTags = (tags: string) => {
        const processedTags = tags.split(',').map(tag =>
            tag
                .replace(/^openapi\./, '')
                .replace(/\.yaml$/, '')
                .replace('getMaterialUrl', 'tuoteohjaus')
        );
        const filteredTags = processedTags.filter(
            tag =>
                tag.indexOf('_') === -1 &&
                !/\d/.test(tag) &&
                !(tag !== tag.toUpperCase() && tag !== tag.toLowerCase())
        );
        const uniqueTags = Array.from(new Set(filteredTags));
        return uniqueTags.map((tag: string) => (
            <Chip sx={{ margin: '.2em' }} key={tag} label={tag} />
        ));
    };

    const resolveLinks = (item: UserEvent) => {
        let links = [];
        if (isUuid(item.product_guid))
            links.push(
                <Link
                    key={`product${item.product_guid}`}
                    to={`/product/${item.product_guid}`}
                >
                    <Tooltip title={`Tuote`}>
                        {createElement(appIcons.product, {
                            color: 'primary',
                            fontSize: 'medium',
                            sx: {
                                verticalAlign: 'middle',
                                marginLeft: '.4em',
                            },
                        })}
                    </Tooltip>
                </Link>
            );
        if (isUuid(item.subscription_guid))
            links.push(
                <Link
                    key={`subscription${item.subscription_guid}`}
                    to={`/subscription/${item.subscription_guid}`}
                >
                    <Tooltip title={`Tilaus`}>
                        {createElement(appIcons.subscription, {
                            color: 'primary',
                            fontSize: 'medium',
                            sx: {
                                verticalAlign: 'middle',
                                marginLeft: '.4em',
                            },
                        })}
                    </Tooltip>
                </Link>
            );
        if (isUuid(item.school_guid))
            links.push(
                <Link
                    key={`school${item.school_guid}`}
                    to={`/school/${item.school_guid}`}
                >
                    <Tooltip title={`Oppilaitos`}>
                        {createElement(appIcons.school, {
                            color: 'primary',
                            fontSize: 'medium',
                            sx: {
                                verticalAlign: 'middle',
                                marginLeft: '.4em',
                            },
                        })}
                    </Tooltip>
                </Link>
            );
        if (isUuid(item.group_guid))
            links.push(
                <Link
                    key={`group${item.group_guid}`}
                    to={`/group/${item.group_guid}`}
                >
                    <Tooltip title={`Ryhmä`}>
                        {createElement(appIcons.group, {
                            color: 'primary',
                            fontSize: 'medium',
                            sx: {
                                verticalAlign: 'middle',
                                marginLeft: '.4em',
                            },
                        })}
                    </Tooltip>
                </Link>
            );
        return links.map(link => link);
    };

    const resolveUserAgent = (userAgent?: string) => {
        if (!userAgent) {
            return (
                <Tooltip title={`User agent: Ei tiedossa`}>
                    <Chip label={`Ei tiedossa`} />
                </Tooltip>
            );
        }
        const { browser, os, platform } = Bowser.parse(userAgent);
        if (!browser.name)
            return (
                <Tooltip title={`User agent: ${userAgent}`}>
                    <Chip
                        label={
                            userAgent.indexOf('Next.js') > -1
                                ? `Nova`
                                : `Ei tiedossa`
                        }
                    />
                </Tooltip>
            );
        return (
            <Tooltip title={`User agent: ${userAgent}`}>
                <Box>
                    <Chip
                        sx={{ margin: '.2em' }}
                        key={`${browser.name}`}
                        label={`${browser.name}`}
                    />
                    <Chip
                        sx={{ margin: '.2em' }}
                        key={`${os.name}`}
                        label={`${os.name}`}
                    />
                    <Chip
                        sx={{ margin: '.2em' }}
                        key={`${platform.type}`}
                        label={`${platform.type}`}
                    />
                </Box>
            </Tooltip>
        );
    };

    /*
    Sample data:
    {
        "timestamp": "2024-11-15T18:12:01.535Z",
        "level": "info",
        "product_guid": "",
        "message": "opettaja kirjautui (otava)",
        "tags": "kirjautuminen,opepalvelu_login,openapi.oidc.yaml,oidc,teacher_token",
        "subscription_guid": "",
        "school_guid": "",
        "group_guid": "",
        "user_agent": "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/127.0.0.0 Safari/537.36",
        "ip": "13.53.76.224"
    },    
    */
    return (
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            {translate('statistics.fields.timestamp')}
                        </TableCell>
                        <TableCell>
                            {translate('statistics.fields.message')}
                        </TableCell>
                        <TableCell>
                            {translate('statistics.fields.tags')}
                        </TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map(item => (
                        <TableRow key={item.search_after}>
                            <TableCell>
                                <Stack direction="row" spacing={2}>
                                    <Stack>{resolveLevel(item.level)}</Stack>
                                    <Stack>
                                        {dateTimeFormatter.format(
                                            new Date(item.timestamp)
                                        )}
                                    </Stack>
                                </Stack>
                            </TableCell>
                            <TableCell>
                                {item.message}
                                {resolveLinks(item)}
                            </TableCell>
                            <TableCell>{resolveTags(item.tags)}</TableCell>
                            <TableCell>
                                {resolveUserAgent(item.user_agent)}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const UserEventsTableGraph = (data: UserEvent[]) => {
    return <Alert severity="info">Not implemented yet</Alert>;
};

const UserEvents = (props: { data: UserEvent[] | null } & Handler & Filter) => {
    const translate = useTranslate();
    const { data, handler, mode } = props;
    const totalTransitions = numberFormatter.format(data.length);
    const title = translate('statistics.userevents.title');

    return (
        <>
            {mode === 'Single' &&
                createElement(handler, {
                    key: 'user_events',
                    title: title,
                    subtitle: String(totalTransitions),
                    icon: appIcons.userevent,
                })}
            {mode === 'Graph' &&
                createElement(handler, {
                    title: title,
                    subtitle: String(totalTransitions),
                    icon: appIcons.userevent,
                    children: UserEventsTableGraph(data),
                })}
            {mode === 'List' && createElement(UserEventsTable, { data })}
        </>
    );
};
export default UserEvents;
