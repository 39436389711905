import * as React from 'react';
import {  Typography,  StepLabel } from '@mui/material';
import { memo } from 'react';

import {  useRecordContext, useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';
import get from 'lodash/get';



interface Props {
    createLink?: boolean;
    // useIcon?: boolean;
    source: string;
    useLabel?: boolean;
    label?: string;
    services?: boolean;
    sortable?: boolean;
}

const linkUrl = (g) => {
    let baseUrl = "/school";
    return baseUrl + '/' + g;
}

const handleOnClik = (e, g) => {
    e.stopPropagation();
//    window.location.href=linkUrl(g);
}

const SchoolFullNameField = ({
    createLink = false,
    source = '',
    useLabel = false,
    label = 'resources.product.name',
    services = false,
    sortable = false,
}: Props) => {
    const translate = useTranslate();
    const record = useRecordContext();
    // const school = useRecordContext();
    // const record = school[source];
    //console.log(record);

    return record ? (
        createLink ? 
            <>
            {useLabel && <StepLabel>{label ? translate(label, { smart_count: 1 }) : ""}</StepLabel>}
            <Link to={linkUrl(record.guid)} onClick={(e) => handleOnClik(e, record.guid)}>
                    <Typography
                        variant="body2"
                        display="flex"
                        flexWrap="nowrap"
                        alignItems="center"
                        component="div"
                    >
                        {record.name}
                    </Typography>
            </Link></> : 
            <>
            {useLabel && <StepLabel>{label ? translate(label, { smart_count: 1 }) : ""}</StepLabel>}
            <Typography
                variant="body2"
                display="flex"
                flexWrap="nowrap"
                alignItems="center"
                component="div"
            >
                {record.name}
                {services && get(record,'private.services.opepalvelu') ? <span style={{backgroundColor: '#afbec5',fontSize:10,color: 'rgb(255, 255, 255)',padding: '1px 6px',borderRadius: 3,background: 'rgba(96, 125, 139, 0.5)',height: 15,marginLeft:3}}>OOP</span>:''} {services && get(record,'private.services.finnlectura') ? <span style={{backgroundColor: '#afbec5',fontSize:10,color: 'rgb(255, 255, 255)',padding: '1px 6px',borderRadius: 3,background: 'rgba(96, 125, 139, 0.5)',height: 15,marginLeft:3}}>FL</span>:''}
            </Typography></>
    ) : null;
};

export default memo<Props>(SchoolFullNameField);
