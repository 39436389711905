import * as React from 'react';
import { Typography, Avatar, StepLabel } from '@mui/material';
import { memo } from 'react';

import { useRecordContext, useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';
import ProductIcon from '@mui/icons-material/Collections';

interface Props {
    createLink?: boolean;
    useIcon?: boolean;
    source: string;
    useLabel?: boolean;
    label?: string;
    sortable?: boolean;
}

const linkUrl = (guid) => `/product/${guid}`;

const handleOnClick = (e, guid) => {
    e.stopPropagation();
    // window.location.href = linkUrl(guid);
};

const ProductFullNameField = ({
    createLink = false,
    useIcon = false,
    source = '',
    useLabel = false,
    label = 'resources.product.name',
    sortable = false
}: Props) => {
    const translate = useTranslate();
    const product = useRecordContext();
    const record = product[source];

    return record ? (
        createLink ? (
            <>
                {useLabel && <StepLabel>{label ? translate(label, { smart_count: 1 }) : ""}</StepLabel>}
                <Link to={linkUrl(record.guid)} onClick={(e) => handleOnClick(e, record.guid)} style={{ textDecoration: 'none' }}>
                    <Typography
                        variant="body2"
                        display="flex"
                        flexWrap="nowrap"
                        alignItems="center"
                        component="div"
                    >
                        {useIcon && <Avatar style={{ width: '30px', height: '30px', marginRight: '5px' }}><ProductIcon /></Avatar>}
                        {record.name}
                    </Typography>
                </Link>
            </>
        ) : (
            <>
                {useLabel && <StepLabel>{label ? translate(label, { smart_count: 1 }) : ""}</StepLabel>}
                <Typography
                    variant="body2"
                    display="flex"
                    flexWrap="nowrap"
                    alignItems="center"
                    component="div"
                >
                    {useIcon && <Avatar style={{ width: '30px', height: '30px', marginRight: '5px' }}><ProductIcon /></Avatar>}
                    {record.name}
                </Typography>
            </>
        )
    ) : null;
};

export default memo(ProductFullNameField);
