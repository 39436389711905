import * as React from 'react';
import { useNotify, useRecordContext } from 'react-admin';
import Button from '@mui/material/Button';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const CustomLogAsStudentButton = () => {
  
  const record = useRecordContext();
  const notify = useNotify();
  const application = 'a7faf0f2-3cfc-4f62-9a77-aa75134ac5a4';
  const ttl = 60;

  const handleClick = async (e) => {
    e.stopPropagation();

    const api = `${process.env.REACT_APP_INFRA_API}/aspa/create_auth_token`;
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(api, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`, 
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user_guid: record.id,
          app_id: application,
          ttl: ttl,
        })
      });

      if (response.status >= 400) {
        throw new Error("Bad response from server");
      }

      const data = await response.json();
      const baseUrl = {
        dev: 'https://oppimateriaalit-id-dev.otava.fi/v1/oidc/student/login',
        next: 'https://oppimateriaalit-id-next.otava.fi/v1/oidc/student/login',
        beta: 'https://oppimateriaalit-id-beta.otava.fi/v1/oidc/student/login',
        localhost: 'http://localhost:8086/v1/oidc/student/login',
        default: 'https://oppimateriaalit-id.otava.fi/v1/oidc/student/login'
      };

      const env = Object.keys(baseUrl).find(key => new RegExp(`${key}`).test(window.location.hostname)) || 'default';
      window.open(`${baseUrl[env]}?authtoken=${data.token}&hash=${data.hash}`, '_blank');

    } catch (e) {
      console.error(e);
      notify(`notifications.MATERIAL_PREVIEW_ERROR`, { type: 'warning' });
    }
  }

  return (
    <Button onClick={handleClick} disabled={record.status != 0}>
      <RemoveRedEyeIcon />
    </Button>
  );
};

export default CustomLogAsStudentButton;