import * as React from 'react';
import { FC } from 'react';
import { useTranslate, useEditContext, List, Datagrid, ListProps } from 'react-admin';
import CustomEmpty from './CustomEmpty';
import CustomSelectInput from './CustomSelectInput';
import CustomUserLicenseField from './CustomUserLicenseField';
import ProductFullNameField from '../product/ProductFullNameField';

interface Props extends ListProps {
    bulkActionButtons?: false | React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
    sortable?: boolean;
    showExtra?: any;
    hideFields?: any;
    customFilters?: any;
    refreshKey?: string;
}

const CustomUserLicenseByUserList: FC<Props> = ({
    bulkActionButtons,
    sortable = true,
    showExtra = [],
    hideFields = [],
    customFilters = [],
    refreshKey = 'refreshAll',
    ...props
}) => {
    const translate = useTranslate();
    const parent = useEditContext();
    const filter = customFilters.includes("user_guid") ? {"user_guid": parent.record.guid} : undefined;

    return (
        <List {...props} actions={false} filter={filter} filters={showExtra.includes("search") ? [<CustomSelectInput url="extralist/subscription/groups" source="group_guid" label={translate('resources.userlicense.fields.groups')} filter="guid" alwaysOn />] : undefined} empty={<CustomEmpty />}>
            <Datagrid bulkActionButtons={bulkActionButtons}>
                {!hideFields.includes("product") && <ProductFullNameField source="product" key="product.name" createLink={true} useIcon={false} sortable={sortable} />}
                {!hideFields.includes("licenses") && <CustomUserLicenseField source="licenses" label={translate('resources.userlicense.fields.licenses')} />}
            </Datagrid>
        </List>
    );
};

export default CustomUserLicenseByUserList;
