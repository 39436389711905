import {
    CustomRoutes,
    mergeTranslations,
    //Resource,
    useStore, //uus
    StoreContextProvider, //uus
    localStorageStore, //uus
} from 'react-admin';
import { Resource } from '@react-admin/ra-rbac';
import { Admin, buildI18nProvider } from '@react-admin/ra-enterprise';
import { addEventsForMutations } from '@react-admin/ra-audit-log';
import CssBaseline from '@mui/material/CssBaseline';
import {
    raTreeLanguageEnglish,
    raTreeLanguageFrench,
} from '@react-admin/ra-tree';
import {
    raTourLanguageEnglish,
    raTourLanguageFrench,
} from '@react-admin/ra-tour';
import { Route } from 'react-router';

import authProvider from './authProvider';
import { Login, Layout } from './layout';
import { Dashboard } from './dashboard';
import englishMessages from './i18n/en';
import frenchMessages from './i18n/fr';
import finnishMessages from './i18n/fi';
import dataProvider from './dataProvider';
import { QueryClient } from '@tanstack/react-query';
import Configuration from './configuration/Configuration';

import product from './resources/product';
import subscription from './resources/subscription';
import school from './resources/school';
import user from './resources/user';
import teacher from './resources/user/teacher';
import pupil from './resources/user/pupil';
import student from './resources/user/student';
import announcement from './resources/announcement';
import group from './resources/group';

import { withLifecycleCallbacks } from 'react-admin';
import { productLifecycleCallbacks } from './resources/product';

import { ThemeName, themes } from './themes/themes';

const messages = {
    en: mergeTranslations(
        englishMessages,
        raTreeLanguageEnglish,
        raTourLanguageEnglish
    ),
    fr: mergeTranslations(
        frenchMessages,
        raTreeLanguageFrench,
        raTourLanguageFrench
    ),
    fi: mergeTranslations(
        finnishMessages,
        raTreeLanguageEnglish, //täytyy selvittää löytyykö suomea
        raTourLanguageEnglish
    ),
};
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            /**
             * The time in milliseconds after data is considered stale.
             * If set to `Infinity`, the data will never be considered stale.
             */
            staleTime: 10000,
            /**
             * If `false`, failed queries will not retry by default.
             * If `true`, failed queries will retry infinitely., failureCount: num
             * If set to an integer number, e.g. 3, failed queries will retry until the failed query count meets that number.
             * If set to a function `(failureCount, error) => boolean` failed queries will retry until the function returns false.
             */
            retry: false,
            /**
             * If set to `true`, the query will refetch on window focus if the data is stale.
             * If set to `false`, the query will not refetch on window focus.
             * If set to `'always'`, the query will always refetch on window focus.
             * If set to a function, the function will be executed with the latest data and query to compute the value.
             * Defaults to `true`.
             */
            refetchOnWindowFocus: false,
        },
    },
});

const i18nProvider = buildI18nProvider(messages, 'fi');



const enhancedDataProvider = addEventsForMutations(
    withLifecycleCallbacks(dataProvider, [productLifecycleCallbacks]),
    authProvider
);

const store = localStorageStore(undefined, 'InfraAdmin'); //uus

const App = () => {
    const [themeName] = useStore<ThemeName>('themeName', 'soft'); //uus
    const lightTheme = themes.find(theme => theme.name === themeName)?.light;
    const darkTheme = themes.find(theme => theme.name === themeName)?.dark;

    return (
        <Admin
            title=""
            store={store} //uus
            dataProvider={enhancedDataProvider}
            authProvider={authProvider}
            dashboard={Dashboard}
            loginPage={Login}
            layout={Layout}
            i18nProvider={i18nProvider}
            disableTelemetry
            lightTheme={lightTheme}
            darkTheme={darkTheme}
            defaultTheme="light"
            queryClient={queryClient}
        >
            <CustomRoutes>
                <Route path="/configuration" element={<Configuration />} />
            </CustomRoutes>
            <CustomRoutes noLayout>
                <Route path="/oidc-login/:code" element={<Login />} />
            </CustomRoutes>
            <CssBaseline />
            <Resource name="product" {...product} />
            <Resource name="subproduct" />
            <Resource name="subscription" {...subscription} />
            <Resource name="school" {...school} />
            <Resource name="group" {...group} />
            <Resource name="user" {...user} />
            <Resource name="teacher" {...teacher} />
            <Resource name="pupil" {...pupil} />
            <Resource name="student" {...student} />
            <Resource name="announcement" {...announcement} />
            <Resource name="userlicense" />
            <Resource name="userapplication" />
            <Resource name="usergroup" />
            <Resource name="logevent" />
        </Admin>
    );
};

const AppWrapper = () => (
    <StoreContextProvider value={store}>
        <App />
    </StoreContextProvider>
);

export default AppWrapper;
