import * as React from 'react';
import {
    Datagrid,
    Edit,
    NumberInput,
    BooleanInput,
    ReferenceManyField,
    TextInput,
    TabbedForm,
    FormTab,
    SaveButton,
    Toolbar,
    FileInput,
    FileField,
    ReferenceField,
    FunctionField,
    DateField,
    DateInput,
    Labeled,
    useTranslate,
    TextField,
    BooleanField,
    useEditContext,
} from 'react-admin';
import {
    required,
    minValue,
    maxValue,
    minLength,
    maxLength,
    number,
    email,
    regex,
    choices,
} from 'react-admin';
import { Grid, Box, Typography } from '@mui/material';


import {
    CustomSelectInput,
    CustomSelectArrayInput,
    CustomCheckboxGroupInput,
    CustomRadioButtonGroupInput,
    CustomJSONPretty,
    CustomTextField,
    CustomBulkDeleteButton,
    CustomBulkAddButton,
    CustomProductList,
    CustomNumberInput,
    CustomLogEventList,
    CustomUserLicenseByUserList,
    CustomUserSchoolList,
} from '../controls';

import { 
    LibraryBooks,
    Article,
    Podcasts,
    Attachment,
    DataObject,
    TransferWithinAStation,
    History,
    Computer,
 } from '@mui/icons-material';

 import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";

const EditToolbar = () => {
    return (
        <Toolbar>
            <SaveButton />
        </Toolbar>
    );
};

const ApplicationForm = () => {
    const useEdit = useEditContext();
    //console.log('record', record);
    return (
        <RowForm defaultValues={{user_guid: useEdit.record.guid}}>
            <CustomSelectInput resource="userapplication" source="application_guid" url="extralist/userapplication/applications" label="" validate={[required()]} />
            <BooleanInput resource="userapplication" source="isadmin" label="" />
            <CustomSelectInput resource="userapplication" source="public.userrights" url="extralist/userapplication/userrights" label="" showIfControl="application_guid" showIfValue="13b312b5-367c-4c74-bb77-bdb34df4e721" validate={[required()]} />
            <CustomSelectInput resource="userapplication" source="status" url="extralist/userapplication/statuses" label="" validate={[required()]} />
        </RowForm>
    );
}

const UserEdit = () => {

    const translate = useTranslate();

    return (
        <Edit redirect={false} mutationMode="optimistic">
            <TabbedForm toolbar={<EditToolbar />} >
                <FormTab label="resources.user.tabs.profile" icon={<Article />}>
                    
                        <Grid container width={{ xs: '100%' }} spacing={2}>
                            <Grid item xs={12} md={8}>
                                <TextInput resource="user" source="public.firstname" validate={[required()]} fullWidth />
                                <TextInput resource="user" source="public.lastname" validate={[required()]} fullWidth />
                                <TextInput resource="user" source="username" validate={[required()]} fullWidth />
                                <TextInput resource="user" source="public.description" fullWidth />
                                <CustomSelectInput resource="user" source="status" url="extralist/user/statuses" parse={v => parseInt(v)} fullWidth />
                                
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Typography variant="subtitle1">{translate('resources.user.fields.group.valid')}</Typography>
                                <Typography variant="body2">{translate('resources.user.fields.group.valid_desc')}</Typography>
                                <Box display={{ xs: 'block', sm: 'flex' }}>
                                    <DateInput resource="user" source="public.validfrom" />
                                </Box>
                                <Box display={{ xs: 'block', sm: 'flex' }}>
                                    <DateInput resource="user" source="public.validto" />
                                </Box>  

                                <BooleanInput resource="user" source="public.login_not_allowed" />
                                <BooleanInput resource="user" source="public.profile_locked" />
                            
                                <Box display={{ xs: 'block', sm: 'flex' }} sx={{marginBottom: '10px'}}>
                                    <Labeled>
                                        <DateField resource="user" source="last_login" showTime />
                                    </Labeled>
                                </Box>
                                <Box display={{ xs: 'block', sm: 'flex' }} sx={{marginBottom: '10px'}}>
                                    <Labeled>
                                        <ReferenceField reference="user" source="author.creator">
                                            <FunctionField render={record => `${record.public.firstname} ${record.public.lastname}, `} />
                                            <DateField resource="user" source="author.created" showTime />
                                        </ReferenceField>
                                    </Labeled>
                                    
                                </Box>
                                <Box display={{ xs: 'block', sm: 'flex' }} sx={{marginBottom: '10px'}}>
                                    <Labeled>
                                        <ReferenceField reference="user" source="author.modifier">
                                            <FunctionField render={record => `${record.public.firstname} ${record.public.lastname}, `} />
                                            <DateField resource="user" source="author.modified" showTime />
                                        </ReferenceField>
                                    </Labeled>
                                </Box>
                            </Grid>
                        </Grid>
                    
                </FormTab>
                <FormTab label="resources.user.tabs.application" icon={<Computer />} path="palvelut">
                    
                        <Grid container width={{ xs: '100%' }} spacing={2}>
                            <Grid item xs={12} md={12}>
                                <ReferenceManyField reference="userapplication" target="user_guid">
                                    <EditableDatagrid createForm={<ApplicationForm />} editForm={<ApplicationForm />}>
                                        <CustomSelectInput resource="userapplication" source="application_guid" label={translate('resources.user.fields.application.name')} url="extralist/userapplication/applications" showAsText={true} />
                                        <BooleanField resource="userapplication" source="isadmin" label={translate('resources.user.fields.application.isadmin')}  />
                                        <CustomSelectInput resource="userapplication" source="public.userrights" label={translate('resources.user.fields.application.role')} url="extralist/userapplication/userrights" showAsText={true} />
                                        <CustomSelectInput resource="userapplication" source="status" label={translate('resources.user.fields.application.status')} url="extralist/userapplication/statuses" showAsText={true} />
                                    </EditableDatagrid>
                                </ReferenceManyField>
                            </Grid>
                        </Grid>
                    
                </FormTab>

                {/* <FormTab label="resources.user.tabs.schools_and_groups" icon={<LibraryBooks />} path="koulut_ja_ryhmat">
                    
                    <Grid container width={{ xs: '100%' }} spacing={2}>
                        <Grid item xs={12} md={12}>
                            <CustomUserSchoolList disableSyncWithLocation storeKey='userSchoolList' resource='usergroup' filter={{type:2}} customFilters={['user_guid']} showExtra={['']} hideFields={['name']} bulkActionButtons={false}><span /></CustomUserSchoolList>
                        </Grid>
                    </Grid>
                    
                </FormTab>
                <FormTab label="resources.teacher.tabs.materials" icon={<LibraryBooks />} path="kaytossa">
                    
                        <Grid container width={{ xs: '100%' }} spacing={2}>
                            <Grid item xs={12} md={12}>
                                <CustomUserLicenseByUserList resource='userlicense' disableSyncWithLocation customFilters={['user_guid']} showExtra={[]} hideFields={[]} sort={{ field: 'product.name', order: 'ASC' }} bulkActionButtons={<CustomBulkDeleteButton />}><span /></CustomUserLicenseByUserList>
                            </Grid>
                        </Grid>
                    
                </FormTab>
                <FormTab label="resources.teacher.tabs.merge" icon={<LibraryBooks />} path="yhdistaminen">
                    
                        <Grid container width={{ xs: '100%' }} spacing={2}>
                            <Grid item xs={12} md={12}>
                                
                            </Grid>
                        </Grid>
                    
                </FormTab> */}
            </TabbedForm>
        </Edit>
    );
};

const redirect: any = () => {
    return window.location.hash.replace('#', '');
}

export default UserEdit;