import * as React from 'react';
import { FC } from 'react';
import { useWatch } from 'react-hook-form';
import { List, Datagrid, TextField, TextInput, useTranslate, useEditContext, FilterFormClasses, useListController, ListControllerProps, ListProps, useStore, Pagination, FunctionField } from 'react-admin';
import CustomUrlBooleanInput from './CustomUrlBooleanInput';
import CustomUrlButton from './CustomUrlButton';
import CustomEmpty from './CustomEmpty';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import InfoIcon from '@mui/icons-material/Info';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import get from 'lodash/get';


interface Props extends ListProps {
    // resource: string;
    // filters?: React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactElement<any, string | React.JSXElementConstructor<any>>[] | undefined;
    // storeKey?: string;
    bulkActionButtons?: false | React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
    sortable?: boolean;
    showExtra?: any;
    hideFields?: any;
    customFilters?: any;
    refreshKey?: string;
    // filter: PropTypes.Requireable<object>;
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 400,
      fontSize: theme.typography.pxToRem(11),
      border: '1px solid #dadde9',
    },
  }));
// const CustomProductList = (props: Props) => {
const CustomProductList = (props: Props) => {
    const {
        sortable = true,
        showExtra = [],
        hideFields = [],
        customFilters = [],
        refreshKey = 'refreshAll',
        // ...other props
    } = props;

    
    // const [refreshKey, setRefreshKey] = useStore(props.refreshKey);
    // console.log(refreshKey);


    const translate = useTranslate();

    const parent = useEditContext();
    var filter = {};
    if (props.customFilters?.includes("parent_product_guid"))
        filter["parent_product_guid"] = parent.record.guid;

    if (props.customFilters?.includes("exclude_child_of_guid"))
        filter["exclude_child_of_guid"] = parent.record.guid;

    if (props.customFilters?.includes("use_query_min_length"))
        filter["qminlen"] = 3;

    if (props.customFilters?.includes("use_v2"))
        filter["version"] = 2;
    //const filter = props.customFilters?.includes("parent_product_guid") ? {"parent_product_guid": parent.record.guid} : (props.customFilters?.includes("exclude_child_of_guid") ? {"exclude_child_of_guid": parent.record.guid} : undefined);

    return (
        <List pagination={<Pagination rowsPerPageOptions={[10,25,50,100,200]} />} {...props} actions={false} filter={filter} filters={props.showExtra?.includes("search") ? [<TextInput label={translate('pos.search')} source="q" size="small" alwaysOn />] : undefined} empty={false}>
            <Datagrid bulkActionButtons={props.bulkActionButtons}>
                {!props.hideFields?.includes("isbn") && <TextField source="public.materialstorage.isbn" label={translate('resources.product.fields.public.materialstorage.isbn')} sortable={props.sortable} />}
                {!props.hideFields?.includes("category") && <TextField source="public.category" label={translate('resources.product.fields.public.category')} sortable={props.sortable} />}
                {!props.hideFields?.includes("name") && <TextField source="name" label={translate('resources.product.fields.name')} sortable={props.sortable} />}
                {props.showExtra?.includes("info") 
                    && <Box style={{width: '30px'}}><FunctionField render={record =>  get(record,'alituotteet',[]).length !== 0 || get(record,'paatuotteet',[]).length !== 0 ? 
                    <HtmlTooltip title={<React.Fragment>
                        <Typography color="inherit" fontWeight="bold" fontSize="13px">Alituotteet</Typography>{get(record,'alituotteet',[]).length !== 0 ? get(record,'alituotteet',[]).map(at => {return <span style={{display: 'block'}}>{at.name}</span>;}) : <span style={{display: 'block'}}>-</span>}
                        <Typography color="inherit" fontWeight="bold" fontSize="13px">Päätuotteena tuotteille</Typography>{get(record,'paatuotteet',[]).length !== 0 ? get(record,'paatuotteet',[]).map(at => {return <span style={{display: 'block'}}>{at.name}</span>;}) : <span style={{display: 'block'}}>-</span>}
                    </React.Fragment>}><InfoTwoToneIcon style={{fontSize: 'large', margin: '0px'}} /></HtmlTooltip> : ''} /></Box>}
                {props.showExtra?.includes("free_to_download") && <CustomUrlBooleanInput label={translate('resources.product.fields.public.free_to_download')} source="public.free_to_download" url={`product/##id##/#guid#/isfree/`} urlparams={['guid','id']} />}
                {props.showExtra?.includes("free_for_extra") && <CustomUrlBooleanInput label={translate('resources.product.fields.public.free_for_extra')} source="public.free_for_extra" url={`product/##id##/#guid#/isextra/`} urlparams={['guid','id']} />}
                {props.showExtra?.includes("ordering") && <Box style={{width: '130px'}}>
                    <CustomUrlButton style={{float: 'left'}} icon={<KeyboardArrowUpIcon style={{fontSize: 'large'}} />} url={`product/##id##/#guid#/direction/up`} urlparams={['guid','id']} hideIf={[{"key":"sort_num","val_key":"sort_num_min"}]} />
                    <CustomUrlButton style={{float: 'right'}} icon={<KeyboardArrowDownIcon style={{fontSize: 'large'}} />} url={`product/##id##/#guid#/direction/down`} urlparams={['guid','id']} hideIf={[{"key":"sort_num","val_key":"sort_num_max"}]} /></Box>}
            </Datagrid>
        </List>
    );
};

//const subFilters = [<TextInput label={translate('resources.product.fields.public.free_to_download')} source="q" size="small" alwaysOn />];

export default CustomProductList;
