import * as React from 'react';
import { useRecordContext } from 'react-admin';
import {get,find} from 'lodash';

interface Props {
    source?: string;
    label?: string;
    sortable?: boolean;
}

const RoleNameField = ({ source = "group", label = "", sortable = false }: Props) => {
    const record = useRecordContext();
    
    let role = get((find(record.group,{type:2}) || record.group[0]),'role_name');
    let isAdmin = get((find(record.group,{type:2}) || record.group[0]),'public.isadmin', false);

    return (
        <div>{role}{isAdmin ? ', Ylläpitäjä': ''}</div>
    );
};

export default RoleNameField;
