import * as React from 'react';
import {
    CreateButton,
    DateField,
    FilterButton,
    List,
    NumberField,
    SearchInput,
    TopToolbar,
    TextField,
    DatagridConfigurable,
    SelectColumnsButton,
    useTranslate,
    EditButton,
    WithListContext,
    DatagridLoading,
} from 'react-admin';
import { useMediaQuery, Theme } from '@mui/material';

import CustomStatusField from '../controls/CustomStatusField';
import SubscriptionListAside from './SubscriptionListAside';
import ProductFullNameField from '../product/ProductFullNameField';
import SubscriberFullNameField from './SubscriberFullNameField';
import CustomEmpty from '../controls/CustomEmpty';
import CustomPagination from '../common/CustomPagination';

const productFilters = [<SearchInput source="q" alwaysOn />];

const ListActions = () => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <TopToolbar>
            {isSmall ? <FilterButton /> : null}
            <SelectColumnsButton />
            {/* <CreateButton /> */}
            {/* <ExportButton /> */}
            {/* <ListViewButton /> */}
        </TopToolbar>
    );
};

//const postRowClick = (id, resource, record) => `/${resource}/${id}/show`;

const SubscriptionList = () => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const sortable = [
        'name',
        'product',
        'subscriber',
        'created',
        'modified',
        'valid_from',
        'valid_until',
        'status',
    ];

    const translate = useTranslate();

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));

    return (
        <List
            filters={isSmall ? productFilters : undefined}
            sort={{ field: 'created', order: 'DESC' }}
            perPage={25}
            aside={<SubscriptionListAside />}
            actions={<ListActions />}
            empty={<CustomEmpty />}
            filter={{ need_count: false }}
            pagination={true ? <CustomPagination /> : undefined}
        >
            <WithListContext
                render={({ isLoading, isFetching, data }) =>
                    isLoading || isFetching ? (
                        <DatagridLoading nbChildren={9} nbFakeLines={8} />
                    ) : (
                        <DatagridConfigurable
                            //optimized
                            omit={['guid']}
                            rowClick="edit"
                            //rowClick={postRowClick}
                            sx={{
                                '& .column-groups': {
                                    md: { display: 'none' },
                                    lg: { display: 'table-cell' },
                                },
                            }}
                        >
                            {/* {columns} */}
                            <TextField
                                source="guid"
                                key="guid"
                                sortable={sortable.includes('guid')}
                            />
                            <ProductFullNameField
                                source="product"
                                key="product.name"
                                createLink={true}
                                useIcon={true}
                                sortable={sortable.includes('product')}
                            />
                            <SubscriberFullNameField
                                source="subscriber"
                                key="subscriber.name"
                                createLink={true}
                                useIcon={true}
                                sortable={sortable.includes('subscriber')}
                            />
                            <DateField
                                source="created"
                                key="created"
                                sortable={sortable.includes('created')}
                            />
                            <DateField
                                source="modified"
                                key="modified"
                                sortable={sortable.includes('modified')}
                            />
                            <DateField
                                source="valid_from"
                                key="valid_from"
                                sortable={sortable.includes('valid_from')}
                            />
                            <DateField
                                source="valid_until"
                                key="valid_until"
                                sortable={sortable.includes('valid_until')}
                            />
                            <NumberField
                                source="license_count"
                                key="license_count"
                                sortable={sortable.includes('license_count')}
                            />
                            <NumberField
                                source="used_license_count"
                                key="used_license_count"
                                sortable={sortable.includes(
                                    'used_license_count'
                                )}
                            />
                            <CustomStatusField
                                source="status"
                                key="status"
                                url="extralist/subscription/statuses"
                                sortable={sortable.includes('status')}
                            />
                            {/* <EditButton label="Muokkaa" /> */}
                        </DatagridConfigurable>
                    )
                }
            />
        </List>
    );
};

export default SubscriptionList;
