import * as React from 'react';
import { List, Datagrid, TextField, DateField, DateInput, useTranslate, useEditContext, ListProps } from 'react-admin';
import CustomEmpty from './CustomEmpty';
import GridOffIcon from '@mui/icons-material/GridOff';

interface Props extends ListProps {
    bulkActionButtons?: false | React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
    sortable?: boolean;
    showExtra?: any;
    hideFields?: any;
    customFilters?: any;
    refreshKey?: string;
    uselog: string;
}

const CustomLogEventList = (props: Props) => {
    const {
        sortable = true,
        showExtra = [],
        hideFields = [],
        customFilters = [],
        refreshKey = 'refreshAll',
        uselog,
        // ...other props
    } = props;

    const translate = useTranslate();
    const parent = useEditContext();
    const filter = props.customFilters?.includes("resource_guid") ? {"resource_guid": parent.record.guid, "uselog": props.uselog} :  undefined;

    return (
        <List {...props} actions={false} filter={filter} filters={props.showExtra?.includes("search") ? [<DateInput source='date_from' label={translate(`resources.logevent.${props.uselog}.filter.date_from`)} alwaysOn />,<DateInput source='date_to' label={translate(`resources.logevent.${props.uselog}.filter.date_to`)} alwaysOn />] : undefined} empty={<CustomEmpty icon={GridOffIcon} />}>
            <Datagrid bulkActionButtons={false}>
                {!props.hideFields?.includes("date") && <DateField showTime={true} source="date" label={translate(`resources.logevent.${props.uselog}.fields.date`)} sortable={props.sortable} />}
                {!props.hideFields?.includes("author") && <TextField source="author" label={translate(`resources.logevent.${props.uselog}.fields.author`)} sortable={props.sortable} />}
                {!props.hideFields?.includes("title") && <TextField source="title" label={translate(`resources.logevent.${props.uselog}.fields.title`)} sortable={props.sortable} />}
                {!props.hideFields?.includes("subtitle") && <TextField source="subtitle" label={translate(`resources.logevent.${props.uselog}.fields.subtitle`)} sortable={props.sortable} />}
                {!props.hideFields?.includes("description") && <TextField source="description" label={translate(`resources.logevent.${props.uselog}.fields.description`)} sortable={props.sortable} />}
            </Datagrid>
        </List>
    );
};

export default CustomLogEventList;
