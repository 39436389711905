import Face5Icon from '@mui/icons-material/Face5';
import PupilList from './PupilList';
import PupilEdit from './PupilEdit';
// import ProductCreate from './ProductCreate';

export default {
    list: PupilList,
    // create: ProductCreate,
    edit: PupilEdit,
    icon: Face5Icon,
};